import { uploadProfilePictureReducer } from "@user-management-context/write/application/use-cases/student/profile-picture-upload/upload-profile-picture-reducer";
import { loginUserReducer } from "@user-management-context/write/application/use-cases/user-login/login-user-reducer";
import { updateStudentReducer } from "@user-management-context/write/application/use-cases/admin/student-update/update-student-reducer";
import { retrieveStudentReducer } from "@user-management-context/read/application/use-cases/admin/student-retrieval/retrieve-student-reducer";
import { createStudentReducer } from "@user-management-context/write/application/use-cases/admin/admin-student-creation/create-student-reducer";
import { createFundingRequestReducer } from "@academy-context/write/application/use-cases/student/funding-request-creation/create-funding-request-reducer";
import { retrieveStudentsReducer } from "@user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students-reducer";
import { createTeacherReducer } from "@user-management-context/write/application/use-cases/admin/admin-teacher-creation/create-teacher-reducer";
import { retrieveTeacherReducer } from "@user-management-context/read/application/use-cases/admin/admin-teacher-retrieval/retrieve-teacher-reducer";
import { retrieveTeachersReducer } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers-reducer";
import { updateTeacherReducer } from "@user-management-context/write/application/use-cases/admin/teacher-update/update-teacher-reducer";
import { createBudgetReducer } from "@academy-context/write/application/use-cases/admin/admin-budget-creation/create-budget-reducer";
import { retrieveBudgetsReducer } from "@academy-context/read/application/use-cases/admin/budgets-retrieval/retrieve-budgets-reducer";
import { updateUserReducer } from "@user-management-context/write/application/use-cases/user-update/update-user-reducer";
import { createLessonReducer } from "@academy-context/write/application/use-cases/teacher/lesson-creation/create-lesson-reducer";
import { retrieveLessonsReducer } from "@academy-context/read/application/use-cases/teacher/lessons-retrieval/retrieve-lessons-reducer";
import { retrieveTransferSummaryReducer } from "@academy-context/read/application/use-cases/admin/admin-transfer-summary-retrieval/retrieve-transfer-summary-reducer";
import { updateTransferReducer } from "@academy-context/write/application/use-cases/admin/admin-transfer-update/update-transfer-reducer";
import { inviteUserReducer } from "@user-management-context/write/application/use-cases/admin/admin-user-invitation/invite-user-reducer";
import { retrieveUserInvitationsReducer } from "@user-management-context/read/application/use-cases/admin/user-invitations-retrieval/retrieve-user-invitations-reducer";
import { resetUserPasswordReducer } from "@user-management-context/write/application/use-cases/user-password-reset/reset-user-password-reducer";
import { createAdminAccountReducer } from "@user-management-context/write/application/use-cases/admin/account-creation/create-admin-account-reducer";
import { updatePasswordReducer } from "@user-management-context/write/application/use-cases/password-update/update-password-reducer";
import { createStudentAccountReducer } from "@user-management-context/write/application/use-cases/student/student-account-creation/create-student-account-reducer";
import { retrieveLastBudgetsReducer } from "@academy-context/read/application/use-cases/admin/admin-last-budgets-retrieval/retrieve-last-budgets-reducer";
import { adminCreateLessonReducer } from "@academy-context/write/application/use-cases/admin/lesson-creation/create-lesson-reducer";
import { adminRetrieveLastLessonsReducer } from "@academy-context/read/application/use-cases/admin/lessons-retrieval/last-seasons/retrieve-last-lessons-reducer";
import { adminRetrieveLessonsReducer } from "@academy-context/read/application/use-cases/admin/lessons-retrieval/all/retrieve-lessons-reducer";
import { retrieveTransferListReducer } from "@academy-context/read/application/use-cases/admin/admin-transfer-list-retrieval/retrieve-transfer-list-reducer";
import { retrieveTeacherProfileReducer } from "@user-management-context/read/application/use-cases/teacher/teacher-profile-retrieval/retrieve-teacher-reducer";
import { updateTeacherUserReducer } from "@user-management-context/write/application/use-cases/teacher/teacher-update/update-teacher-reducer";
import { retrieveTeacherStudentsReducer } from "@user-management-context/read/application/use-cases/teacher/students-retrieval/retrieve-students-reducer";
import { retrieveStudentProfileReducer } from "@user-management-context/read/application/use-cases/student/profile-retrieval/retrieve-student-profile-reducer";
import { updateStudentProfileReducer } from "@user-management-context/write/application/use-cases/student/update-profile/update-student-profile-reducer";
import { retrieveStudentSummaryReducer } from "@academy-context/read/application/use-cases/student/summary-retrieval/retrieve-student-summary-reducer";
import { retrieveUserProfileReducer } from "@user-management-context/read/application/use-cases/user-profile/retrieve-user-profile-reducer";
import { retrieveStudentPublicProfileReducer } from "@user-management-context/read/application/use-cases/student/public-profile-retrieval/retrieve-student-public-profile-reducer";
import { verifyTokenValidityReducer } from "@user-management-context/write/application/use-cases/token-verification/verify-token-validity-reducer";
import { retrieveTeacherPublicProfileReducer } from "@user-management-context/read/application/use-cases/teacher/public-profile-retrieval/retrieve-teacher-public-profile-reducer";
import { createTeacherAccountReducer } from "@user-management-context/write/application/use-cases/teacher/account-creation/create-teacher-account-reducer";
import { studentRetrieveLessonsReducer } from "@academy-context/read/application/use-cases/student/lessons-retrieval/retrieve-lessons-reducer";
import { createExpenseReducer } from "@academy-context/write/application/use-cases/admin/expense-creation/create-expense-reducer";
import { retrieveExpensesReducer } from "@academy-context/read/application/use-cases/admin/expenses-retrieval/retrieve-expenses-reducer";
import { studentRetrieveExpensesReducer } from "@academy-context/read/application/use-cases/student/expenses-retrieval/retrieve-expenses-reducer";
import { createConventionReducer } from "@academy-context/write/application/use-cases/admin/convention-creation/create-convention-reducer";
import { retrieveConventionsReducer } from "@academy-context/read/application/use-cases/admin/conventions-retrieval/retrieve-conventions-reducer";
import { retrieveConventionTransferListReducer } from "@academy-context/read/application/use-cases/admin/admin-convention-transfer-list-retrieval/retrieve-convention-transfer-list-reducer";
import { retrieveTeacherConventionsReducer } from "@academy-context/read/application/use-cases/teacher/conventions-retrieval/retrieve-conventions-reducer";
import { retrieveStudentConventionsReducer } from "@academy-context/read/application/use-cases/student/conventions-retrieval/retrieve-conventions-reducer";
import { retrieveStudentBudgetsReducer } from "@academy-context/read/application/use-cases/student/budgets-retrieval/retrieve-budgets-reducer";
import { retrievePaymentLessonListReducer } from "@academy-context/read/application/use-cases/admin/payment-lesson-list-retrieval/retrieve-payment-lesson-list-reducer";
import { uploadBioReducer } from "@academy-context/write/application/use-cases/student/bio-upload/upload-bio-reducer";
import { uploadResumeReducer } from "@academy-context/write/application/use-cases/student/resume-upload/upload-resume-reducer";
import { studentRetrieveTeachersReducer } from "@user-management-context/read/application/use-cases/student/teachers-retrieval/retrieve-teachers-reducer";
import { retrieveFundingRequestEligibilityReducer } from "@academy-context/read/application/use-cases/student/funding-request-eligibility-retrieval/retrieve-funding-request-eligibility-reducer";
import { retrieveToReviewFundingRequestsReducer } from "@academy-context/read/application/use-cases/admin/funding-request-list/to-review-funding-request-list-retrieval/retrieve-to-review-funding-requests-reducer";
import { retrieveFundingRequestReducer } from "@academy-context/read/application/use-cases/admin/funding-request-retrieval/retrieve-funding-request-reducer";
import { deleteFundingRequestReducer } from "@academy-context/write/application/use-cases/admin/funding-request-deletion/delete-funding-request-reducer";
import { retrieveSubmittedToFunderFundingRequestsReducer } from "@academy-context/read/application/use-cases/admin/funding-request-list/submitted-to-afdas/retrieve-submitted-to-afdas-funding-requests-reducer";
import { retrieveReviewedFundingRequestsReducer } from "@academy-context/read/application/use-cases/admin/funding-request-list/reviewed-funding-request-list-retrieval/retrieve-reviewed-funding-requests-reducer";
import { retrieveApprovedFundingRequestsReducer } from "@academy-context/read/application/use-cases/admin/funding-request-list/approved/retrieve-approved-funding-requests-reducer";
import { retrieveTrainingSessionsReducer } from "@academy-context/read/application/use-cases/shared/training-sessions-retrieval/retrieve-training-sessions-reducer";
import { signAttendanceSheetReducer } from "@academy-context/write/application/use-cases/shared/attendance-sheet-signature/sign-attendance-sheet-reducer";
import { studentRetrieveTrainingsReducer } from "@academy-context/read/application/use-cases/student/trainings-retrieval/retrieve-trainings-reducer";
import { studentRetrieveTrainingReducer } from "@academy-context/read/application/use-cases/student/training-retrieval/retrieve-training-reducer";
import { retrieveTrainingObjectivesReducer } from "@academy-context/read/application/use-cases/shared/training-objectives-retrieval/retrieve-training-objectives-reducer";
import { updateTrainingObjectiveReducer } from "@academy-context/write/application/use-cases/shared/training-objective-update/update-training-objective-reducer";
import { teacherRetrieveTrainingsReducer } from "@academy-context/read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings-reducer";
import { teacherRetrieveTrainingReducer } from "@academy-context/read/application/use-cases/teacher/training-retrieval/retrieve-training-reducer";
import { adminRetrieveTrainingsReducer } from "@academy-context/read/application/use-cases/admin/trainings-retrieval/retrieve-trainings-reducer";
import { adminRetrieveTrainingReducer } from "@academy-context/read/application/use-cases/admin/training-retrieval/retrieve-training-reducer";
import { adminRetrieveTrainingObjectivesReducer } from "@academy-context/read/application/use-cases/admin/training-objectives-retrieval/retrieve-training-objectives-reducer";
import { uploadSignedConventionReducer } from "@academy-context/write/application/use-cases/admin/signed-convention-upload/upload-signed-convention-reducer";
import { deleteSignedConventionReducer } from "@academy-context/write/application/use-cases/admin/signed-convention-deletion/delete-signed-convention-reducer";
import { updateFundingRequestErrorReducer } from "@academy-context/write/application/use-cases/admin/funding-request-error-update/update-funding-request-error-reducer";
import { cancelAndDuplicateFundingRequestReducer } from "@academy-context/write/application/use-cases/admin/funding-request-cancel-and-duplicate/cancel-and-duplicate-funding-request-reducer";
import { retrieveOldConventionsReducer } from "@academy-context/read/application/use-cases/admin/old-conventions-retrieval/retrieve-old-conventions-reducer";
import { retrieveTeacherOldConventionsReducer } from "@academy-context/read/application/use-cases/teacher/old-conventions-retrieval/retrieve-old-conventions-reducer";
import { cancelConventionReducer } from "@academy-context/write/application/use-cases/admin/convention-cancellation/cancel-convention-reducer";
import { endConventionReducer } from "@academy-context/write/application/use-cases/admin/convention-ending/end-convention-reducer";
import { requestRefundReducer } from "@academy-context/write/application/use-cases/student/refund-request/request-refund-reducer";
import { uploadInvoiceReducer } from "@academy-context/write/application/use-cases/student/invoice-upload/upload-invoice-reducer";
import { retrieveRefundListReducer } from "@academy-context/read/application/use-cases/admin/refund-list-retrieval/retrieve-refund-list-reducer";
import { requestCollaborationReducer } from "@academy-context/write/application/use-cases/teacher/collaboration-request/request-collaboration-reducer";
import { retrieveCollaborationRequestListReducer } from "@academy-context/read/application/use-cases/admin/collaboration-request-list-retrieval/retrieve-collaboration-request-list-reducer";
import { approveCollaborationRequestReducer } from "@academy-context/write/application/use-cases/admin/collaboration-request-approval/approve-collaboration-request-reducer";
import { teacherRetrieveStudentReducer } from "@user-management-context/read/application/use-cases/teacher/student-retrieval/retrieve-student-reducer";
import { reviewFundingRequestReducer } from "@academy-context/write/application/use-cases/admin/funding-request-review/review-funding-request-reducer";
import { submitFundingRequestToAfdasReducer } from "@academy-context/write/application/use-cases/admin/funding-request-submission-to-afdas/submit-funding-request-to-afdas-reducer";
import { createInstantFundingRequestReducer } from "@academy-context/write/application/use-cases/admin/instant-funding-request-creation/create-instant-funding-request-reducer";
import { updateRefundReducer } from "@academy-context/write/application/use-cases/admin/refund-update/update-refund-reducer";
import { toggleTrainingDunningsStatusReducer } from "@academy-context/write/application/use-cases/admin/training-dunnings-status-toggle/toggle-training-dunnings-status-reducer";
import { retrieveAdminPublicProfileReducer } from "@user-management-context/read/application/use-cases/admin/public-profile-retrieval/retrieve-admin-public-profile-reducer";
import { retrieveUnsubscribedUserListReducer } from "@user-management-context/read/application/use-cases/admin/unsubscribed-user-list-retrieval/retrieve-unsubscribed-user-list-reducer";
import { doesUserExistReducer } from "@user-management-context/read/application/use-cases/admin/user-account-existence/does-user-account-exist-reducer";
import { isEmailUsedReducer } from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used-reducer";
import { adminRetrieveProviderStudentInvitationsReducer } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/retrieve-provider-student-invitations-reducer";
import { acceptProviderStudentInvitationReducer } from "@user-management-context/write/application/use-cases/admin/provider-student-invitation-acceptation/accept-provider-student-invitation-reducer";
import { refuseProviderStudentInvitationReducer } from "@user-management-context/write/application/use-cases/admin/provider-student-invitation-refusal/refuse-provider-student-invitation-reducer";
import { adminRetrieveProviderTrainingsReducer } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings-reducer";
import { adminCreateProviderTrainingReducer } from "@academy-context/write/application/use-cases/admin/provider-training-creation/create-provider-training-reducer";
import { adminUpdateProviderTrainingReducer } from "@academy-context/write/application/use-cases/admin/provider-training-update/update-provider-training-reducer";
import { adminRetrieveProvidersReducer } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers-reducer";
import { adminDeleteProviderTrainingReducer } from "@academy-context/write/application/use-cases/admin/provider-training-deletion/delete-provider-training-reducer";
import { createProviderReducer } from "@user-management-context/write/application/use-cases/admin/provider-creation/create-provider-reducer";
import { retrieveProviderReducer } from "@user-management-context/read/application/use-cases/admin/provider-retrieval/retrieve-provider-reducer";
import { updateProviderReducer } from "@user-management-context/write/application/use-cases/admin/provider-update/update-provider-reducer";
import { adminRetrieveProviderInternshipsReducer } from "@academy-context/read/application/use-cases/admin/provider-internships-retrieval/retrieve-provider-internships-reducer";
import { adminConfirmProviderInternshipReducer } from "@academy-context/write/application/use-cases/admin/provider-internship-confirmation/confirm-provider-internship-reducer";
import { adminUpdateProviderInternshipReducer } from "@academy-context/write/application/use-cases/admin/provider-internship-update/update-provider-internship-reducer";
import { retrieveProviderStudentInvitationsReducer } from "@user-management-context/read/application/use-cases/provider/student-invitations-retrieval/retrieve-provider-student-invitations-reducer";
import { retrieveProviderStudentByEmailReducer } from "@user-management-context/read/application/use-cases/provider/student-retrieval-by-email/retrieve-student-by-email-reducer";
import { inviteStudentToInternshipReducer } from "@academy-context/write/application/use-cases/provider/student-internship-invitation/invite-student-to-internship-reducer";
import { retrieveProviderPublicProfileReducer } from "@user-management-context/read/application/use-cases/provider/public-profile-retrieval/retrieve-provider-public-profile-reducer";
import { createProviderAccountReducer } from "@user-management-context/write/application/use-cases/provider/provider-account-creation/create-provider-account-reducer";
import { retrieveProviderProfileReducer } from "@user-management-context/read/application/use-cases/provider/provider-profile-retrieval/retrieve-provider-reducer";
import { updateProviderProfileReducer } from "@user-management-context/write/application/use-cases/provider/provider-profile-update/update-provider-profile-reducer";
import { createProviderTrainingReducer } from "@academy-context/write/application/use-cases/provider/provider-training-creation/create-provider-training-reducer";
import { updateProviderTrainingReducer } from "@academy-context/write/application/use-cases/provider/provider-training-update/update-provider-training-reducer";
import { deleteProviderTrainingReducer } from "@academy-context/write/application/use-cases/provider/provider-training-deletion/delete-provider-training-reducer";
import { retrieveProviderTrainingsReducer } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings-reducer";
import { studentRetrieveProvidersReducer } from "@user-management-context/read/application/use-cases/student/providers-retrieval/retrieve-providers-reducer";
import { retrieveProviderInternshipsReducer } from "@academy-context/read/application/use-cases/provider/provider-internships-retrieval/retrieve-provider-internships-reducer";
import { confirmProviderInternshipReducer } from "@academy-context/write/application/use-cases/provider/provider-internship-confirmation/confirm-provider-internship-reducer";
import { updateProviderInternshipReducer } from "@academy-context/write/application/use-cases/provider/provider-internship-update/update-provider-internship-reducer";
import { studentRetrieveProviderTrainingsReducer } from "@academy-context/read/application/use-cases/student/provider-trainings-retrieval/retrieve-provider-trainings-reducer";

const reducers = {
  studentRetrieval: retrieveStudentReducer,
  studentPublicProfileRetrieval: retrieveStudentPublicProfileReducer,
  studentSummaryRetrieval: retrieveStudentSummaryReducer,
  studentProfileRetrieval: retrieveStudentProfileReducer,
  studentsRetrieval: retrieveStudentsReducer,
  studentProfileUpdate: updateStudentProfileReducer,
  studentLessonsRetrieval: studentRetrieveLessonsReducer,
  teacherStudentsRetrieval: retrieveTeacherStudentsReducer,
  studentUpdate: updateStudentReducer,
  studentCreate: createStudentReducer,
  teacherCreate: createTeacherReducer,
  teacherRetrieval: retrieveTeacherReducer,
  teacherProfileRetrieval: retrieveTeacherProfileReducer,
  teacherPublicProfileRetrieval: retrieveTeacherPublicProfileReducer,
  teachersRetrieval: retrieveTeachersReducer,
  studentTeachersRetrieval: studentRetrieveTeachersReducer,
  studentProvidersRetrieval: studentRetrieveProvidersReducer,
  teacherUpdate: updateTeacherReducer,
  teacherUserUpdate: updateTeacherUserReducer,
  fundingRequestCreate: createFundingRequestReducer,
  userLogin: loginUserReducer,
  userUpdate: updateUserReducer,
  userInvite: inviteUserReducer,
  userInvitationsRetrieval: retrieveUserInvitationsReducer,
  resetUserPassword: resetUserPasswordReducer,
  budgetCreate: createBudgetReducer,
  budgetsRetrieval: retrieveBudgetsReducer,
  lastBudgetsRetrieval: retrieveLastBudgetsReducer,
  lessonCreate: createLessonReducer,
  adminLessonCreate: adminCreateLessonReducer,
  lessonsRetrieval: retrieveLessonsReducer,
  adminLessonsRetrieval: adminRetrieveLessonsReducer,
  adminLastLessonsRetrieval: adminRetrieveLastLessonsReducer,
  transferSummaryRetrieval: retrieveTransferSummaryReducer,
  transferListRetrieval: retrieveTransferListReducer,
  conventionTransferListRetrieval: retrieveConventionTransferListReducer,
  transferUpdate: updateTransferReducer,
  createAdminAccount: createAdminAccountReducer,
  updatePassword: updatePasswordReducer,
  createStudentAccount: createStudentAccountReducer,
  createTeacherAccount: createTeacherAccountReducer,
  userProfileRetrieval: retrieveUserProfileReducer,
  tokenValidityCheck: verifyTokenValidityReducer,
  adminPublicProfileRetrieval: retrieveAdminPublicProfileReducer,
  userAccountExistence: doesUserExistReducer,
  userEmailExistence: isEmailUsedReducer,
  expenseCreate: createExpenseReducer,
  expensesRetrieval: retrieveExpensesReducer,
  studentExpensesRetrieval: studentRetrieveExpensesReducer,
  conventionCreate: createConventionReducer,
  adminConventionsRetrieval: retrieveConventionsReducer,
  adminOldConventionsRetrieval: retrieveOldConventionsReducer,
  teacherConventionsRetrieval: retrieveTeacherConventionsReducer,
  teacherOldConventionsRetrieval: retrieveTeacherOldConventionsReducer,
  studentConventionsRetrieval: retrieveStudentConventionsReducer,
  studentBudgetsRetrieval: retrieveStudentBudgetsReducer,
  paymentLessonListRetrieval: retrievePaymentLessonListReducer,
  unsubscribedUserListRetrieval: retrieveUnsubscribedUserListReducer,
  profilePictureUpload: uploadProfilePictureReducer,
  bioUpload: uploadBioReducer,
  resumeUpload: uploadResumeReducer,
  fundingRequestEligibilityRetrieval: retrieveFundingRequestEligibilityReducer,
  toReviewFundingRequestsRetrieval: retrieveToReviewFundingRequestsReducer,
  fundingRequestReview: reviewFundingRequestReducer,
  fundingRequestRetrieval: retrieveFundingRequestReducer,
  fundingRequestDeletion: deleteFundingRequestReducer,
  fundingRequestSubmissionToAfdas: submitFundingRequestToAfdasReducer,
  submittedToFunderFundingRequestsRetrieval: retrieveSubmittedToFunderFundingRequestsReducer,
  reviewedFundingRequestsRetrieval: retrieveReviewedFundingRequestsReducer,
  approvedFundingRequestsRetrieval: retrieveApprovedFundingRequestsReducer,
  trainingSessionsRetrieval: retrieveTrainingSessionsReducer,
  attendanceSheetSignature: signAttendanceSheetReducer,
  studentTrainingsRetrieval: studentRetrieveTrainingsReducer,
  teacherTrainingsRetrieval: teacherRetrieveTrainingsReducer,
  adminTrainingsRetrieval: adminRetrieveTrainingsReducer,
  studentTrainingRetrieval: studentRetrieveTrainingReducer,
  teacherTrainingRetrieval: teacherRetrieveTrainingReducer,
  adminTrainingRetrieval: adminRetrieveTrainingReducer,
  trainingObjectivesRetrieval: retrieveTrainingObjectivesReducer,
  adminTrainingObjectivesRetrieval: adminRetrieveTrainingObjectivesReducer,
  trainingObjectiveUpdate: updateTrainingObjectiveReducer,
  signedConventionUpload: uploadSignedConventionReducer,
  signedConventionDeletion: deleteSignedConventionReducer,
  fundingRequestErrorUpdate: updateFundingRequestErrorReducer,
  fundingRequestCancelAndDuplicate: cancelAndDuplicateFundingRequestReducer,
  conventionCancellation: cancelConventionReducer,
  conventionEnding: endConventionReducer,
  refundRequest: requestRefundReducer,
  invoiceUploads: uploadInvoiceReducer,
  refundListRetrieval: retrieveRefundListReducer,
  refundUpdate: updateRefundReducer,
  trainingDunningsStatusToggle: toggleTrainingDunningsStatusReducer,
  collaborationRequest: requestCollaborationReducer,
  collaborationRequestListRetrieval: retrieveCollaborationRequestListReducer,
  collaborationRequestApproval: approveCollaborationRequestReducer,
  teacherStudentRetrieval: teacherRetrieveStudentReducer,
  instantFundingRequestCreation: createInstantFundingRequestReducer,
  adminProviderStudentInvitationsRetrieval: adminRetrieveProviderStudentInvitationsReducer,
  providerStudentInvitationAcceptation: acceptProviderStudentInvitationReducer,
  providerStudentInvitationRefusal: refuseProviderStudentInvitationReducer,
  adminProviderTrainingsRetrieval: adminRetrieveProviderTrainingsReducer,
  studentProviderTrainingsRetrieval: studentRetrieveProviderTrainingsReducer,
  adminProviderTrainingCreation: adminCreateProviderTrainingReducer,
  adminProviderTrainingUpdate: adminUpdateProviderTrainingReducer,
  adminProviderTrainingDeletion: adminDeleteProviderTrainingReducer,
  providerTrainingCreation: createProviderTrainingReducer,
  providerTrainingUpdate: updateProviderTrainingReducer,
  providerTrainingDeletion: deleteProviderTrainingReducer,
  providerTrainingsRetrieval: retrieveProviderTrainingsReducer,
  adminProviderInternshipsRetrieval: adminRetrieveProviderInternshipsReducer,
  providerInternshipsRetrieval: retrieveProviderInternshipsReducer,
  providerRetrieval: retrieveProviderReducer,
  adminProvidersRetrieval: adminRetrieveProvidersReducer,
  providerCreation: createProviderReducer,
  providerUpdate: updateProviderReducer,
  adminProviderInternshipConfirmation: adminConfirmProviderInternshipReducer,
  providerInternshipConfirmation: confirmProviderInternshipReducer,
  adminProviderInternshipUpdate: adminUpdateProviderInternshipReducer,
  providerInternshipUpdate: updateProviderInternshipReducer,
  providerStudentInvitationsRetrieval: retrieveProviderStudentInvitationsReducer,
  providerStudentRetrievalByEmail: retrieveProviderStudentByEmailReducer,
  studentInternshipInvitation: inviteStudentToInternshipReducer,
  providerPublicProfileRetrieval: retrieveProviderPublicProfileReducer,
  providerAccountCreation: createProviderAccountReducer,
  providerProfileRetrieval: retrieveProviderProfileReducer,
  providerProfileUpdate: updateProviderProfileReducer,
};

export default reducers;
