import { useAppDispatch } from "@redux/hooks";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { AdminConventionListVM } from "@academy-context/read/domain/types/admin/convention";
import { endConvention } from "@academy-context/write/application/use-cases/admin/convention-ending/end-convention";

interface Props {
  convention: AdminConventionListVM;
  isOpen: boolean;
  onClose: () => void;
}

export const EndConventionModal = ({ convention, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const handleOnSubmit = async () => {
    onClose();
    dispatch(endConvention(convention.id));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Terminer prématurément la convention</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <p>Êtes-vous sûr de vouloir terminer la convention?</p>
          <p>Ceci entrainera la fin de la convention. Le suivi de la formation sera toujours actif.</p>
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Annuler
          </Button>
          <Button type="submit" onClick={handleOnSubmit}>
            Terminer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
