import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save, X } from "lucide-react";
import {
  ProviderProfileUpdateFormInputs,
  schema,
  defaultValues,
  providerProfileUpdateFormatDataToFormData,
  providerProfileUpdateFormatFormDataToBodyData,
} from "@user-management-context/primary/provider/provider-user-detail/form-validation/provider";
import { UpdateProviderProfileBody } from "@user-management-context/write/domain/types/provider/provider";
import { Label } from "@components/ui/label";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: UpdateProviderProfileBody) => void;
  provider: Provider;
}

export const ProviderUserForm = ({ provider, onSubmit }: Props) => {
  const form = useForm<ProviderProfileUpdateFormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = form;

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  const resetForm = useCallback(() => {
    if (provider) reset(providerProfileUpdateFormatDataToFormData(provider));
  }, [provider, reset]);

  useEffect(() => {
    resetForm();
  }, [provider, resetForm]);

  const handleOnSubmit = async (formBody: ProviderProfileUpdateFormInputs) => {
    const body = providerProfileUpdateFormatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const phone = watch("mobile");

  return (
    <CustomCard title="Informations">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-2 gap-4">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Raison sociale</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <Label>Email</Label>
              <Input value={provider.email} disabled className="text-black" />
            </div>
            <FormField
              control={form.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN (zone Euro)</FormLabel>
                  <FormControl>
                    <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="siret"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SIRET</FormLabel>
                  <FormControl>
                    <Input {...field} className="text-black" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Adresse complète du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="additionalAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ville et code postal du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-2 flex justify-end">
            <Button onClick={resetForm} disabled={!isDirty} variant="outline">
              <X className="mr-2 size-4" />
              Annuler
            </Button>
            <Button type="submit" disabled={!isDirty} className="ml-2">
              <Save className="mr-2 size-4" />
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
