import { ReactNode } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@shared-kernel/primary/shared/shadcn/ui/table";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  headers: {
    classname?: string;
    title: Nullable<string | ReactNode>;
  }[];
  entries: { value: Nullable<string | number> | JSX.Element; classname?: string }[][];
}

export const SimpleTable = ({ headers, entries }: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="hover:bg-white">
          {headers.map((h, index) => (
            <TableHead className={h.classname} key={index}>
              {h.title}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {entries.map((e, index) => (
          <TableRow key={index}>
            {e.map(({ value, classname }, index) => (
              <TableCell className={classname} key={index}>
                {value}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
