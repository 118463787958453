import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingObjectives } from "../../../read/application/use-cases/shared/training-objectives-retrieval/retrieve-training-objectives";
import { TrainingObjectives } from "../../shared/training-objective-form/training-objectives.components";
import { useObjectiveUpdate } from "../../shared/training/use-objective-update";

export const TeacherTrainingObjectives = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: objectives } = useAppSelector((state: AppState) => state.trainingObjectivesRetrieval);

  useEffect(() => {
    if (id) dispatch(retrieveTrainingObjectives({ trainingId: id, role: "teacher" }));
  }, [dispatch, id]);

  useObjectiveUpdate({ trainingId: id!, role: "teacher" });

  if (!id) return <></>;

  return <TrainingObjectives objectives={objectives} role="teacher" trainingId={id} />;
};
