import config from "../../../../../config/backend";
import { AdminTeacherGateway } from "@user-management-context/shared/application/ports/admin-teacher-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { CreateTeacherBody, UpdateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";
import { Teacher, TeacherListElement } from "@user-management-context/read/domain/types/admin/teacher";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";

export class AdminBackendTeacherGateway extends HttpGateway implements AdminTeacherGateway {
  private _route: string = "v1/admin/teachers";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async one(id: string): Promise<Teacher> {
    const { data: teacher } = await this._instance.get(`${this._route}/${id}`);
    return teacher;
  }

  async all(): Promise<TeacherListElement[]> {
    const { data: teachers } = await this._instance.get(this._route);
    return teachers;
  }

  async create(teacher: CreateTeacherBody): Promise<string> {
    const { data: teacherId } = await this._instance.post(this._route, teacher);
    return teacherId;
  }

  async update(id: string, body: UpdateTeacherBody): Promise<string> {
    const { data: teacherId } = await this._instance.put(`${this._route}/${id}`, body);
    return teacherId;
  }
}
