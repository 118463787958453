import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputs, schema, defaultValues, formatFormDataToBodyDataCreateFundingRequest } from "./form-validation/funding-request";
import { CreateFundingRequestBody } from "@academy-context/write/domain/types/student/funding-request";
import RichText from "@shared-kernel/primary/shared/rich-text/rich-text";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { resetUploadBio, uploadBio } from "../../../write/application/use-cases/student/bio-upload/upload-bio";
import { resetUploadResume, uploadResume } from "../../../write/application/use-cases/student/resume-upload/upload-resume";
import { BIO_AND_RESUME_FILETYPE, FileProps, isValidFile } from "@academy-context/write/domain/constants/shared";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Send, ArrowUpRightFromCircle } from "lucide-react";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { UploadButton } from "@shared-kernel/primary/shared/upload-button/upload-button";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import {
  resetStudentRetrieveProviderTrainings,
  studentRetrieveProviderTrainings,
} from "@academy-context/read/application/use-cases/student/provider-trainings-retrieval/retrieve-provider-trainings";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

const getFileProps = async (file: File): Promise<FileProps> => {
  return {
    fileSize: file.size,
    type: file.type,
  };
};

interface Props {
  onSubmit: (body: CreateFundingRequestBody) => void;
}

export const FundingRequestForm = ({ onSubmit }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { bioUrl, processing: bioProcessing } = useAppSelector((state: AppState) => state.bioUpload);
  const { resumeUrl, processing: resumeProcessing } = useAppSelector((state: AppState) => state.resumeUpload);
  const { data: teachers } = useAppSelector((state: AppState) => state.studentTeachersRetrieval);
  const { data: providers } = useAppSelector((state: AppState) => state.studentProvidersRetrieval);
  const { data: providerTrainings } = useAppSelector((state: AppState) => state.studentProviderTrainingsRetrieval);
  const { fetching } = useAppSelector((state: AppState) => state.fundingRequestCreate);
  const [switchState, setSwitchState] = useState(true);
  const [trainingOptions, setTrainingOptions] = useState<ComboboxOptions[]>([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);

  const {
    formState: { errors, isDirty, isSubmitted },
    setValue,
    control,
    resetField,
    clearErrors,
    setError,
    watch,
  } = form;

  const educationalAdvisorId = watch("educationalAdvisorId");
  const educationalAdvisorType = watch("educationalAdvisorType");
  const isProvider = educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setValue("educationalAdvisorId", "", { shouldDirty: false });
    setValue("trainingTitle", "", { shouldDirty: false });
  }, [switchState, setValue]);

  useEffect(() => {
    if (isProvider && educationalAdvisorId) dispatch(studentRetrieveProviderTrainings(educationalAdvisorId));
    else dispatch(resetStudentRetrieveProviderTrainings());
  }, [dispatch, educationalAdvisorId, isProvider]);

  useEffect(() => {
    if (isProvider) {
      const trainings = providerTrainings.map(training => ({ label: training.title, value: training.title }));
      setTrainingOptions(trainings);
    }
  }, [isProvider, providerTrainings]);

  useEffect(() => {
    if (bioUrl) {
      setValue("bioUrl", bioUrl, { shouldDirty: true });
    }
  }, [bioUrl, setValue]);

  useEffect(() => {
    if (resumeUrl) {
      setValue("resumeUrl", resumeUrl, { shouldDirty: true });
    }
  }, [resumeUrl, setValue]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    setShouldBlockNavigation(false);
    const body = formatFormDataToBodyDataCreateFundingRequest(formBody);
    onSubmit(body);
  };

  const coverLetter = watch("coverLetter");
  const personalizedEducationalProject = watch("personalizedEducationalProject");

  const handleCoverLetterChange = (value: { html: string; text: string }) => {
    const isDirty = control._defaultValues.coverLetter !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue("coverLetter", value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue("coverLetterText", value.text.trim(), { shouldDirty: isDirty, shouldValidate: isSubmitted });
    } else {
      resetField("coverLetter");
      resetField("coverLetterText");
    }
  };

  const handleResetCoverLetter = (value: string) => {
    resetField("coverLetter", { defaultValue: value });
  };

  const handlePersonalizedEducationalProjectChange = (value: { html: string; text: string }) => {
    const isDirty = control._defaultValues.personalizedEducationalProject !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue("personalizedEducationalProject", value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue("personalizedEducationalProjectText", value.text.trim(), { shouldDirty: isDirty, shouldValidate: isSubmitted });
    } else {
      resetField("personalizedEducationalProject");
      resetField("personalizedEducationalProjectText");
    }
  };

  const handleResetPersonalizedEducationalProject = (value: string) => {
    resetField("personalizedEducationalProject", { defaultValue: value });
  };

  const handleResumeUrlChange = (resume: File) => {
    clearErrors("resumeUrl");
    dispatch(uploadResume({ resume }));
  };

  const handleResumeUrlError = () => {
    setError("resumeUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const handleResumeUrlDelete = () => {
    clearErrors("resumeUrl");
    setValue("resumeUrl", "", { shouldDirty: true });
    dispatch(resetUploadResume());
  };

  const handleBioUrlChange = (bio: File) => {
    clearErrors("bioUrl");
    dispatch(uploadBio({ bio }));
  };

  const handleBioUrlError = () => {
    setError("bioUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const handleBioUrlDelete = () => {
    clearErrors("bioUrl");
    setValue("bioUrl", "", { shouldDirty: true });
    dispatch(resetUploadBio());
  };

  const teacherOptions: ComboboxOptions[] = teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.id }));
  const providerOptions: ComboboxOptions[] = useMemo(() => {
    const allProviderOptions = providers.map(p => ({ label: p.name, value: p.id }));
    return allProviderOptions;
  }, [providers]);

  const bioFromForm = watch("bioUrl");
  const bio = bioUrl || bioFromForm;
  const resumeFromForm = watch("resumeUrl");
  const resume = resumeUrl || resumeFromForm;

  return (
    <CustomCard title="Nouvelle demande de financement AFDAS">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="educationalAdvisorId"
            render={() => (
              <FormItem>
                <FormLabel>Professeur / Prestataire principal</FormLabel>
                <FormDescription>
                  Professeur / Prestataire auquel vous allouerez la majeure partie de ce financement AFDAS (c’est-à-dire environ 30% du
                  budget)
                </FormDescription>
                <RadioGroup
                  onValueChange={() => {
                    const opposite = isProvider ? EDUCATIONAL_ADVISOR.TEACHER : EDUCATIONAL_ADVISOR.PROVIDER;
                    setValue("educationalAdvisorType", opposite);
                    setSwitchState(!switchState);
                  }}
                  value={educationalAdvisorType}
                  className="flex flex-col space-y-1 text-black"
                >
                  {Object.values(EDUCATIONAL_ADVISOR).map((v, index) => (
                    <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                      <FormControl>
                        <RadioGroupItem value={v} />
                      </FormControl>
                      <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
                <FormControl>
                  {isProvider ? (
                    <Combobox
                      options={providerOptions}
                      value={educationalAdvisorId}
                      onChange={value => setValue("educationalAdvisorId", value, { shouldDirty: true })}
                      placeholder="Selectionner prestataire..."
                      search={{
                        notFoundText: "Pas de prestataire trouvé.",
                        commandInputPlaceHolder: "Chercher prestataire...",
                      }}
                    />
                  ) : (
                    <Combobox
                      options={teacherOptions}
                      value={educationalAdvisorId}
                      onChange={value => setValue("educationalAdvisorId", value, { shouldDirty: true })}
                      placeholder="Selectionner professeur..."
                      search={{
                        notFoundText: "Pas de professeur trouvé.",
                        commandInputPlaceHolder: "Chercher professeur...",
                      }}
                    />
                  )}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {isProvider && (
            <FormField
              control={form.control}
              name="trainingTitle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Intitulé de la formation</FormLabel>
                  <FormControl>
                    <Combobox
                      options={trainingOptions}
                      value={watch("trainingTitle")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner formation..."
                      search={{
                        notFoundText: "Pas de formation trouvé.",
                        commandInputPlaceHolder: "Chercher formation...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="afdasId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Votre identifiant AFDAS</FormLabel>
                <FormControl>
                  <Input placeholder={`Adresse mail Ex: marc@gmail.com`} {...field} type="email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="afdasPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Votre mot de passe AFDAS</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormItem>
            <FormLabel>Télécharger Bio et/ou CV</FormLabel>
            <FormDescription>Dans l’idéal, fournissez votre bio ET votre CV</FormDescription>
            <FormField
              control={form.control}
              name="bioUrl"
              render={() => (
                <FormItem>
                  <div className="flex items-center">
                    <UploadButton
                      processing={bioProcessing}
                      label="Télécharger ma bio (pdf)"
                      onChange={handleBioUrlChange}
                      onError={handleBioUrlError}
                      types={BIO_AND_RESUME_FILETYPE}
                      value={bio}
                      validatorFunc={isValidFile}
                      onDelete={handleBioUrlDelete}
                      getFileProps={getFileProps}
                    />
                    {bio && (
                      <a href={bio} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                        <span className="text-sm font-normal">Aperçu</span>
                        <ArrowUpRightFromCircle size={18} />
                      </a>
                    )}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="resumeUrl"
              render={() => (
                <FormItem className="mt-2">
                  <div className="flex items-center">
                    <UploadButton
                      processing={resumeProcessing}
                      label="Télécharger mon CV (pdf)"
                      onChange={handleResumeUrlChange}
                      onError={handleResumeUrlError}
                      types={BIO_AND_RESUME_FILETYPE}
                      value={resume}
                      validatorFunc={isValidFile}
                      onDelete={handleResumeUrlDelete}
                      getFileProps={getFileProps}
                    />
                    {resume && (
                      <a href={resume} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                        <span className="text-sm font-normal">Aperçu</span>
                        <ArrowUpRightFromCircle size={18} />
                      </a>
                    )}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FormItem>
          <FormItem>
            <FormLabel>Lettre de motivation</FormLabel>
            <FormDescription>
              Des exemples de lettre de motivation sont consultables{" "}
              <a
                href="https://docs.google.com/document/d/1M99kLEeszi94w2LNOVQbBPJjMK0-JlZGZapfqPCfysg/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-500"
              >
                ici
              </a>
              , vous pouvez reprendre les éléments en les personnalisant.
            </FormDescription>
            <FormControl>
              <RichText
                value={coverLetter}
                onChange={handleCoverLetterChange}
                onReset={handleResetCoverLetter}
                hasError={Boolean(
                  (errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)
                )}
              />
            </FormControl>
            <p className="text-sm font-medium text-destructive">
              {(errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)}
            </p>
          </FormItem>
          <FormItem>
            <FormLabel>Projet pédagogique personnalisé (minimum 10 lignes)</FormLabel>
            <FormDescription>
              Votre projet pédagogique peut se contruire autour des thématiques suivantes: Travail de technique vocale pure, préparation de
              roles figurant à votre agenda (nom des oeuvres, votre rôle, lieux de diffusion), préparation de concours, d’auditions,
              construction de répertoire, ...
              <br />
              Des exemples sont consultables{" "}
              <a
                href="https://docs.google.com/document/d/1xaSFl4qnhhmpkssGbqEU6iJCqARelCz70ApeTbws2P8/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-500"
              >
                ici
              </a>
              .
            </FormDescription>
            <FormControl>
              <RichText
                value={personalizedEducationalProject}
                onChange={handlePersonalizedEducationalProjectChange}
                onReset={handleResetPersonalizedEducationalProject}
                hasError={Boolean(
                  (errors.personalizedEducationalProject && errors.personalizedEducationalProject.message) ||
                    (errors.personalizedEducationalProjectText && errors.personalizedEducationalProjectText.message)
                )}
              />
            </FormControl>
            <p className="text-sm font-medium text-destructive">
              {(errors.personalizedEducationalProject && errors.personalizedEducationalProject.message) ||
                (errors.personalizedEducationalProjectText && errors.personalizedEducationalProjectText.message)}
            </p>
          </FormItem>
          <div className="flex justify-end">
            <Button type="submit" disabled={!isDirty || fetching === "pending"}>
              <Send className="mr-2 size-4" />
              Envoyer ma demande pour validation
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
