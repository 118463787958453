import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema,
  defaultValues,
  formatProviderFormDataToBodyData,
  ProviderFormInputs,
  formatProviderDataToFormData,
} from "./form-validation/provider";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save, X } from "lucide-react";
import { CreateProviderBody, UpdateProviderBody } from "@user-management-context/write/domain/types/admin/provider";
import { Switch } from "@components/ui/switch";
import { Provider } from "@user-management-context/read/domain/types/admin/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: CreateProviderBody | UpdateProviderBody) => void;
  provider: Nullable<Provider>;
  doesEmailExist: boolean;
  checkIfEmailUsed: (email: string) => void;
}
export const ProviderForm = ({ provider, onSubmit, doesEmailExist, checkIfEmailUsed }: Props) => {
  const methods = useForm<ProviderFormInputs>({ resolver: yupResolver(schema), defaultValues, context: { validation: "admin" } });
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const isProviderAlreadyExisting = Boolean(provider);

  const {
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { isDirty },
  } = methods;

  const resetForm = useCallback(() => {
    if (provider) reset(formatProviderDataToFormData(provider));
    else reset();
  }, [provider, reset]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  const email = watch("email");

  // This part is used to check if user account is already registered
  useEffect(() => {
    if (email) checkIfEmailUsed(email);
  }, [checkIfEmailUsed, email]);

  useEffect(() => {
    const condition = provider ? provider.email !== email : email;
    if (doesEmailExist && condition) {
      setError("email", { message: "Cet email est déjà utilisé", type: "custom" });
      setDisableSubmit(true);
    } else {
      clearErrors("email");
      setDisableSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, doesEmailExist]);

  const handleOnSubmit = (formBody: ProviderFormInputs) => {
    // Should not block navigation on creation otherwise modal opens up since we redirect user to newly created provider
    if (!provider) setShouldBlockNavigation(false);
    const body = formatProviderFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const phone = watch("mobile");

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...methods}>
        <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-2 gap-4">
          <div className="space-y-4">
            <FormField
              control={methods.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Raison sociale</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="tag"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tag</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={methods.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN (zone Euro)</FormLabel>
                  <FormControl>
                    <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isProviderAlreadyExisting && (
              <FormField
                control={methods.control}
                name="siret"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>SIRET</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={methods.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Adresse complète du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="additionalAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ville et code postal du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="isTrainingFollowUpManagedByOperaOff"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                      <FormLabel>Gestion suivi par OPERA Off</FormLabel>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="areConventionSentToOperaOff"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                      <FormLabel>Conventions envoyées par OPERA Off</FormLabel>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-4 mt-4 flex justify-end">
            <Button onClick={resetForm} disabled={!isDirty} variant="outline">
              <X className="mr-2 size-4" />
              Annuler
            </Button>
            <Button type="submit" disabled={!isDirty || disableSubmit} className="ml-2">
              <Save className="mr-2 size-4" />
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
