import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";
import { ConventionCreate } from "./convention-create.component";
import { selectConventionsRetrievalForSummmaryList } from "../../../read/application/use-cases/admin/conventions-retrieval/selectors/conventions-list-summary-selectors";
import { ConventionListSummary } from "./convention-list-summary.components";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveConventions } from "../../../read/application/use-cases/admin/conventions-retrieval/retrieve-conventions";
import { createConvention } from "../../../write/application/use-cases/admin/convention-creation/create-convention";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { retrieveApprovedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/approved/retrieve-approved-funding-requests";

export const ConventionAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: conventions } = useAppSelector(selectConventionsRetrievalForSummmaryList);

  useEffect(() => {
    dispatch(retrieveStudents());
    dispatch(adminRetrieveTeachers());
    dispatch(retrieveConventions());
    dispatch(retrieveApprovedFundingRequests());
  }, [dispatch]);

  const saveConvention = async (convention: CreateConventionBody) => {
    await dispatch(createConvention(convention));
  };

  return (
    <div className="space-y-5">
      <ConventionCreate onSubmit={saveConvention} />
      <ConventionListSummary conventions={conventions} />
    </div>
  );
};
