import React, { InputHTMLAttributes, useEffect, useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@shared-kernel/primary/shared/shadcn/ui/table";
import { DataTablePagination } from "@shared-kernel/primary/shared/shadcn/ui/data-table-with-pagination";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { DataTableViewOptions } from "@shared-kernel/primary/shared/shadcn/ui/data-table-view-options";
import { DataTableColumnHeader } from "./data-table-column-header";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sortField?: string;
  searchPlaceHolder?: string;
  order?: "asc" | "desc";
  displayDataViewOptions?: boolean;
  pageSize?: number;
  invisibleColumns?: VisibilityState;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  searchPlaceHolder,
  sortField,
  order,
  displayDataViewOptions = false,
  pageSize = 10,
  invisibleColumns = {},
}: DataTableProps<TData, TValue>) {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(invisibleColumns);
  const sortingInitialState = sortField ? [{ id: sortField, desc: order === "desc" }] : undefined;
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,
    columns,
    initialState: {
      ...(sortingInitialState && { sorting: sortingInitialState }),
      pagination: {
        pageSize,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      columnFilters,
      columnVisibility,
      globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={value => setGlobalFilter(String(value))}
          className="max-w-sm"
          placeholder={searchPlaceHolder}
        />
        {displayDataViewOptions && <DataTableViewOptions table={table} />}
      </div>
      <div className="rounded-md border">
        <Table style={{ tableLayout: "fixed" }}>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const { meta } = header.column.columnDef;
                  const { width } = meta ?? { width: null };
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        width: width ? width : "auto",
                        wordWrap: "break-word", // Allows long words to break and wrap to the next line
                        whiteSpace: "normal", // Allows text to wrap onto multiple lines
                      }}
                    >
                      {/* When sorting is enabled, meta must be set with column name */}
                      {header.column.columnDef.enableSorting ? (
                        <DataTableColumnHeader
                          column={header.column}
                          title={header.column.columnDef.meta?.title ?? "Add a meta property to your column"}
                          displayHide={displayDataViewOptions}
                        />
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map(cell => {
                    const { meta } = cell.column.columnDef;
                    let className;
                    const hasMeta = cell.getContext().cell.column.columnDef.meta;
                    if (hasMeta && hasMeta.getCellContext) {
                      className = hasMeta.getCellContext(cell.getContext()).className;
                    }

                    return (
                      <TableCell
                        key={cell.id}
                        style={{
                          width: meta?.width ? meta.width : "auto",
                          wordWrap: "break-word", // Allows long words to break and wrap to the next line
                          whiteSpace: "normal", // Allows text to wrap onto multiple lines
                        }}
                        className={className}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>Pas de résultats.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return <Input {...props} value={value} onChange={e => setValue(e.target.value)} />;
}
