import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
  MIN_CONVENTION_HOURS,
  MAX_CONVENTION_HOURS,
} from "./form-validation/convention";
import { selectConventionCreate, useConventionCreate } from "./use-convention-create";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { ADMIN_MIN_HOURLY_PRICE, MAX_HOURLY_PRICE } from "@academy-context/write/domain/constants/shared";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Equal, Loader2, Minus, Save } from "lucide-react";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: CreateConventionBody) => void;
}

export const ConventionCreate = ({ onSubmit }: Props) => {
  const students = useAppSelector(selectRegisteredStudents);
  const { data: teachers } = useAppSelector((state: AppState) => state.teachersRetrieval);
  const { fetching } = useAppSelector(selectConventionCreate);
  // TODO: Je me demande s'il ne faut pas filtrer les funding requests spécial convention
  const { data: fundingRequests } = useAppSelector((state: AppState) => state.approvedFundingRequestsRetrieval);

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = form;
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useConventionCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const fundingRequest = watch("fundingRequestId");

  const studentOptions = useMemo(
    () =>
      students.map(s => {
        const hasARunningConvention = Boolean(s.convention);
        return {
          value: s.id,
          label: `${s.name} ${s.lastName} ${hasARunningConvention ? "(Convention en cours)" : ""}`,
          isDisabled: hasARunningConvention,
        };
      }),
    [students]
  );

  const teacherOptions = useMemo(() => teachers.map(t => ({ value: t.teacherId, label: `${t.name} ${t.lastName}` })), [teachers]);

  const hours = watch("hours");
  const hourlyPrice = watch("hourlyPrice");
  const totalAmount = Number(hours || 0) * Number(hourlyPrice || 0);
  const teacher = watch("teacher");

  const fundingRequestOptions = useMemo(
    () =>
      fundingRequests.map(r => {
        const student = students.find(s => s.id === r.student.id);
        const hasARunningConvention = student ? Boolean(student.convention) : false;
        return {
          value: r.id,
          label: `${r.student.name} | ${new Date(r.submissionDate).toLocaleDateString("fr-FR")} ${
            hasARunningConvention ? "(Convention en cours)" : ""
          }`,
          isDisabled: hasARunningConvention,
        };
      }),
    [fundingRequests, students]
  );

  useEffect(() => {
    const selectedFundingRequest = fundingRequests.find(r => r.id === fundingRequest);
    if (selectedFundingRequest) {
      const selectedStudent = studentOptions.find(s => s.value === selectedFundingRequest.student.id);
      if (selectedStudent) {
        setValue("student", selectedStudent.value);
      }
    }
  }, [fundingRequest, fundingRequests, studentOptions, setValue]);

  useEffect(() => {
    const selectedFundingRequest = fundingRequests.find(r => r.id === fundingRequest);
    if (selectedFundingRequest) {
      const selectedTeacher = teacherOptions.find(s => s.value === selectedFundingRequest.educationalAdvisor.id);
      if (selectedTeacher) {
        setValue("teacher", selectedTeacher.value);
      }
    }
  }, [fundingRequest, setValue, fundingRequests, teacherOptions]);

  useEffect(() => {
    if (teacher) {
      const t = teachers.find(t => teacher === t.teacherId);
      if (t) setValue("hourlyPrice", t.defaultPrice);
    }
  }, [teachers, teacher, setValue]);

  const isBeingSubmitted = fetching === "pending";

  return (
    <CustomCard title="Nouvelle convention">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-3">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="student"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Élève</FormLabel>
                  <FormControl>
                    <Combobox
                      options={studentOptions}
                      value={watch("student")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner élève..."
                      search={{
                        notFoundText: "Pas d'élève trouvé.",
                        commandInputPlaceHolder: "Chercher élève...",
                      }}
                      disabled={true}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="teacher"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Professeur</FormLabel>
                  <FormControl>
                    <Combobox
                      options={teacherOptions}
                      value={watch("teacher")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner professeur..."
                      search={{
                        notFoundText: "Pas de professeur trouvé.",
                        commandInputPlaceHolder: "Chercher professeur...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="fundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Demande de financement</FormLabel>
                  <FormControl>
                    <Combobox
                      options={fundingRequestOptions}
                      value={watch("fundingRequestId")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner demande de financement..."
                      search={{
                        notFoundText: "Pas de demande de financement trouvée.",
                        commandInputPlaceHolder: "Chercher demande de financement...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-2">
              <span className="text-red-500">
                <strong>ATTENTION : sans demande de financement, le suivi pédagogique automatique ne sera pas lancé</strong>
              </span>
            </div>
          </div>
          <div className="col-span-2">
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <div className="flex items-end gap-2">
              <FormField
                control={form.control}
                name="hours"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Heures</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="1"
                        min={MIN_CONVENTION_HOURS}
                        max={MAX_CONVENTION_HOURS}
                        {...field}
                        value={watch("hours")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Minus className="mb-2" />
              <FormField
                control={form.control}
                name="hourlyPrice"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tarif horaire</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="1"
                        min={ADMIN_MIN_HOURLY_PRICE}
                        max={MAX_HOURLY_PRICE}
                        {...field}
                        value={watch("hourlyPrice")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Equal className="mb-2" />
              <FormItem>
                <FormLabel>Prix total</FormLabel>
                <FormControl>
                  <Input type="number" disabled value={totalAmount} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Commentaire</FormLabel>
                  <FormControl>
                    <Textarea maxLength={240} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || isBeingSubmitted}>
              {isBeingSubmitted ? <Loader2 className="mr-2 animate-spin" size={24} /> : <Save className="mr-2" size={24} />}
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
