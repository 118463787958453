import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AdminConventionListVM } from "@academy-context/read/domain/types/admin/convention";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { retrieveOldConventions } from "@academy-context/read/application/use-cases/admin/old-conventions-retrieval/retrieve-old-conventions";
import { selectOldConventionsRetrievalForList } from "@academy-context/read/application/use-cases/admin/conventions-retrieval/selectors/old-conventions-list-selectors";

const columns: ColumnDef<AdminConventionListVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: info => formatDateToLocale(info.row.original.date),
    meta: {
      width: "90px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "hours",
    header: () => "Heures",
    meta: {
      title: "Heures",
      width: "65px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire",
    cell: info => formatToCurrency(info.row.original.hourlyPrice),
    meta: {
      title: "Tarif horaire",
      width: "65px",
    },
  },
  {
    accessorKey: "hoursLeft",
    header: () => "Heures restantes",
    meta: {
      title: "Heures restantes",
      width: "70px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    meta: {
      title: "Commentaire",
    },
  },
];

export const OldConventionsList = () => {
  const dispatch = useAppDispatch();
  const { data: conventions } = useAppSelector(selectOldConventionsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveOldConventions());
  }, [dispatch]);

  return (
    <CustomCard title="Convention">
      <DataTable
        columns={columns}
        data={conventions}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher une convention"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
