import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyDataReviewFundingRequest,
  formatFundingRequestDataToFormData,
} from "./form-validation/afdas-funding-request";
import RichText from "@shared-kernel/primary/shared/rich-text/rich-text";
import { useAppSelector } from "@redux/hooks";
import { FundingRequestVM, TrainingDays } from "@academy-context/read/domain/types/admin/funding-request";
import { ReviewFundingRequestBody } from "@academy-context/write/domain/types/admin/funding-request";
import parse from "html-react-parser";
import { TrainingDaysForm } from "./training-days";
import { PersonalizedEducationalProject } from "./personalized-educational-project";
import { AppState } from "@redux/app-state";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { ArrowUpRightFromCircle, Eye, EyeOff, Loader2 } from "lucide-react";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import {
  computeDaysSpreadOver2Months,
  selectDateOptions,
} from "@academy-context/primary/admin/shared/selectors/funding-request/date-options-selectors";
import { OPERA_OFF_TRAINING_OPTIONS } from "@academy-context/write/domain/constants/training/training";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { AdminProviderTrainingRow } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { TeacherListElement } from "@user-management-context/read/domain/types/admin/teacher";

interface Props {
  onSubmit: (body: ReviewFundingRequestBody) => void;
  onDelete: () => void;
  fundingRequest: FundingRequestVM;
  setProvider: (provider: string) => void;
  providers: ProviderListItem[];
  teachers: TeacherListElement[];
  providerTrainings: AdminProviderTrainingRow[];
}

export const FundingRequestForm = ({ onSubmit, fundingRequest, onDelete, setProvider, providers, teachers, providerTrainings }: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { fetching } = useAppSelector((state: AppState) => state.fundingRequestReview);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const now = new Date();
  const dateOptions = selectDateOptions(now);
  // We start at M+2
  const secondDateOption = dateOptions[1]!.value;
  const [month, setMonth] = useState(secondDateOption);
  const trainingDays = useMemo(() => computeDaysSpreadOver2Months(new Date(month)), [month]);
  const [trainingOptions, setTrainingOptions] = useState<ComboboxOptions[]>(OPERA_OFF_TRAINING_OPTIONS);
  const [switchState, setSwitchState] = useState(true);

  const {
    formState: { errors, isDirty, isSubmitted },
    setValue,
    control,
    resetField,
    watch,
    reset,
  } = form;

  const educationalAdvisorId = watch("educationalAdvisorId");
  const educationalAdvisorType = watch("educationalAdvisorType");
  const isProvider = educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;

  useEffect(() => {
    setValue("educationalAdvisorId", "", { shouldDirty: false });
    setValue("trainingName", "", { shouldDirty: false });
    setValue("address", "");
    setValue("additionalAddress", "");
  }, [switchState, setValue]);

  useEffect(() => {
    reset(formatFundingRequestDataToFormData({ ...fundingRequest, trainingDays }));
    // This is intended because we don't want to trigger it at each trainingDays change. Instead we want to trigger the useEffect below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingRequest, reset]);

  useEffect(() => {
    let address: string;
    let additionalAddress: string;
    if (isProvider) {
      const provider = providers.find(p => p.id === educationalAdvisorId);
      address = provider?.address ?? "";
      additionalAddress = provider?.additionalAddress ?? "";
    } else {
      const teacher = teachers.find(t => t.teacherId === educationalAdvisorId);
      address = teacher?.address ?? "";
      additionalAddress = teacher?.additionalAddress ?? "";
    }
    setValue("address", address);
    setValue("additionalAddress", additionalAddress);
  }, [educationalAdvisorId, isProvider, providers, setValue, teachers]);

  useEffect(() => {
    if (isProvider) {
      setProvider(educationalAdvisorId);
    }
  }, [educationalAdvisorId, isProvider, setProvider]);

  useEffect(() => {
    for (const [k, v] of Object.entries(trainingDays)) {
      setValue(k as keyof TrainingDays, v, { shouldDirty: true, shouldTouch: true });
    }
  }, [trainingDays, setValue]);

  useEffect(() => {
    if (isProvider) {
      const trainings = providerTrainings.map(training => ({ label: training.title, value: training.title }));
      setTrainingOptions([...trainings, ...OPERA_OFF_TRAINING_OPTIONS]);
    } else {
      setTrainingOptions(OPERA_OFF_TRAINING_OPTIONS);
    }
  }, [isProvider, providerTrainings]);

  const handleOnSubmit = (formBody: FormInputs) => {
    const body = formatFormDataToBodyDataReviewFundingRequest(formBody);
    onSubmit(body);
  };

  const coverLetter = watch("coverLetter");

  const handleCoverLetterChange = (value: { html: string; text: string }) => {
    const isDirty = control._defaultValues.coverLetter !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue("coverLetter", value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue("coverLetterText", value.text.trim(), { shouldDirty: isDirty, shouldValidate: isSubmitted });
    } else {
      resetField("coverLetter");
      resetField("coverLetterText");
    }
  };

  const handleResetCoverLetter = (value: string) => {
    resetField("coverLetter", { defaultValue: value });
  };

  const teacherOptions: ComboboxOptions[] = teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.teacherId }));
  const providerOptions: ComboboxOptions[] = useMemo(() => {
    const allProviderOptions = providers.map(p => ({ label: p.name, value: p.id }));
    return allProviderOptions;
  }, [providers]);

  const EyeIcon = showPassword ? Eye : EyeOff;

  return (
    <CustomCard title="Nouvelle demande de financement AFDAS">
      <AlertModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          setIsModalOpen(false);
          onDelete();
        }}
        title="Supprimer la demande de financement AFDAS"
        body="Êtes-vous sûr de vouloir supprimer cette demande?"
        submitText="Supprimer"
      />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="w-full space-y-4">
          <div className="grid grid-cols-2 gap-5">
            <div className="space-y-4">
              <FormItem>
                <FormLabel>Elève</FormLabel>
                <FormControl>
                  <Input value={fundingRequest.student.fullName} disabled />
                </FormControl>
              </FormItem>
              <FormItem>
                <FormLabel>Identifiant AFDAS</FormLabel>
                <FormControl>
                  <Input value={fundingRequest.afdasId} disabled />
                </FormControl>
              </FormItem>
              <FormItem>
                <FormLabel>Mot de passe AFDAS</FormLabel>
                <FormControl>
                  <div className="relative">
                    <EyeIcon
                      className="absolute right-2 top-1/2 z-50 -translate-y-1/2 cursor-pointer"
                      color="black"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                    <Input value={fundingRequest.afdasPassword} disabled type={showPassword ? "text" : "password"} />
                  </div>
                </FormControl>
              </FormItem>
              <FormField
                control={form.control}
                name="trainingName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Intitulé de la formation</FormLabel>
                    <FormControl>
                      <Combobox
                        options={trainingOptions}
                        value={watch("trainingName")}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner formation..."
                        search={{
                          notFoundText: "Pas de formation trouvé.",
                          commandInputPlaceHolder: "Chercher formation...",
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="educationalAdvisorId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Professeur / Prestataire principal</FormLabel>
                    <div>
                      <RadioGroup
                        onValueChange={() => {
                          const opposite = isProvider ? EDUCATIONAL_ADVISOR.TEACHER : EDUCATIONAL_ADVISOR.PROVIDER;
                          setValue("educationalAdvisorType", opposite);
                          setSwitchState(!switchState);
                        }}
                        value={educationalAdvisorType}
                        className="mb-2 flex flex-col space-y-1 text-black"
                      >
                        {Object.values(EDUCATIONAL_ADVISOR).map((v, index) => (
                          <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                            <FormControl>
                              <RadioGroupItem value={v} />
                            </FormControl>
                            <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                      <FormControl>
                        {isProvider ? (
                          <Combobox
                            options={providerOptions}
                            value={educationalAdvisorId}
                            onChange={value => setValue("educationalAdvisorId", value, { shouldDirty: true })}
                            placeholder="Selectionner prestataire..."
                            search={{
                              notFoundText: "Pas de prestataire trouvé.",
                              commandInputPlaceHolder: "Chercher prestataire...",
                            }}
                          />
                        ) : (
                          <Combobox
                            options={teacherOptions}
                            value={educationalAdvisorId}
                            onChange={value => field.onChange(value)}
                            placeholder="Selectionner professeur..."
                            search={{
                              notFoundText: "Pas de professeur trouvé.",
                              commandInputPlaceHolder: "Chercher professeur...",
                            }}
                          />
                        )}
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Adresse du lieu des cours</FormLabel>
                    <FormControl>
                      <Input placeholder="Ex: 111 rue de vaugirard" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="additionalAddress"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code postal, Ville</FormLabel>
                    <FormControl>
                      <Input placeholder="Ex: 75006 Paris" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-2">
                <div className="col-span-1 flex flex-col items-center space-y-2">
                  <Label>CV (pdf)</Label>
                  {fundingRequest.resumeUrl && (
                    <a href={fundingRequest.resumeUrl} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                      <ArrowUpRightFromCircle size={18} />
                      <span className="text-sm font-normal">Aperçu</span>
                    </a>
                  )}
                </div>
                <div className="col-span-1 flex flex-col items-center space-y-2">
                  <Label>Bio (pdf)</Label>
                  {fundingRequest.bioUrl && (
                    <a href={fundingRequest.bioUrl} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                      <ArrowUpRightFromCircle size={18} />
                      <span className="text-sm font-normal">Aperçu</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <FormItem>
            <FormLabel>Lettre de motivation de l'élève</FormLabel>
            <FormControl>
              <div className="min-h-[236px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
                {parse(fundingRequest.coverLetter)}
              </div>
            </FormControl>
          </FormItem>
          <FormItem>
            <FormLabel>Lettre de motivation</FormLabel>
            <FormControl>
              <RichText
                value={coverLetter}
                onChange={handleCoverLetterChange}
                onReset={handleResetCoverLetter}
                hasError={Boolean(
                  (errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)
                )}
              />
            </FormControl>
            <p className="text-sm font-medium text-destructive">
              {(errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)}
            </p>
          </FormItem>
          <div className="grid grid-cols-2 gap-5">
            <div className="space-y-4">
              <FormItem>
                <FormLabel>Projet pédagogique personnalisé de l'élève</FormLabel>
                <FormControl>
                  <div className="min-h-[236px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
                    {parse(fundingRequest.personalizedEducationalProject)}
                  </div>
                </FormControl>
              </FormItem>
              <div className="flex justify-center">
                <FormField
                  control={form.control}
                  name="trainingPrice"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Prix de la formation</FormLabel>
                      <FormControl className="w-[100px]">
                        <Input type="number" step="1" min={0} max={4000} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <TrainingDaysForm
                studentId={fundingRequest.student.id}
                now={now}
                dateOptions={dateOptions}
                currentMonth={month}
                onChangeMonth={(value: string) => setMonth(value)}
              />
            </div>
            <div>
              <PersonalizedEducationalProject />
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <div className="flex flex-col">
              {fetching === "pending" ? (
                <div className="mr-3 flex items-center gap-3">
                  <Loader2 className="ml-2 animate-spin" size={24} />
                  <div className="">
                    <p className="mb-1 mt-2">Le dossier est en train d'être traité.</p>
                    <p> Veuillez patienter quelques secondes ...</p>
                  </div>
                </div>
              ) : (
                <>
                  <Button type="submit" disabled={!isDirty}>
                    Valider la demande
                  </Button>
                  <Button type="button" onClick={() => setIsModalOpen(true)} className="mt-5">
                    Supprimer la demande (déconseillé)
                  </Button>
                </>
              )}
            </div>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
