import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../../config/backend";
import {
  CreateProviderTrainingBody,
  ProviderRepository,
  ProviderStudentInternshipInvitationBody,
  UpdateProviderTrainingBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { UpdateProviderProfileBody } from "@user-management-context/write/domain/types/provider/provider";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { ProviderInvitationListItem } from "@user-management-context/read/domain/types/provider/invitation";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";
import { ConfirmInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export class BackendProviderRepository extends HttpGateway implements ProviderRepository {
  private providerRoute: string = "v1/provider";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async invitationList(): Promise<ProviderInvitationListItem[]> {
    const { data: students } = await this._instance.get(`${this.providerRoute}/invitations`);
    return students;
  }

  async inviteStudentToInternship(invitation: ProviderStudentInternshipInvitationBody): Promise<void> {
    await this._instance.post(`${this.providerRoute}/invitations`, invitation);
  }

  async profile(): Promise<Provider> {
    const { data: provider } = await this._instance.get(`${this.providerRoute}/profile`);
    return provider;
  }

  async updateProfile(profile: UpdateProviderProfileBody): Promise<void> {
    await this._instance.put(`${this.providerRoute}/profile`, profile);
  }

  async trainingList(): Promise<ProviderTraining[]> {
    const { data: trainings } = await this._instance.get(`${this.providerRoute}/trainings`);
    return trainings;
  }

  async createTraining(training: CreateProviderTrainingBody): Promise<void> {
    await this._instance.post(`${this.providerRoute}/trainings`, training);
  }
  async updateTraining(training: UpdateProviderTrainingBody): Promise<void> {
    await this._instance.put(`${this.providerRoute}/trainings/${training.trainingId}`, training);
  }
  async deleteTraining(trainingId: string): Promise<void> {
    await this._instance.delete(`${this.providerRoute}/trainings/${trainingId}`);
  }

  async internshipList(): Promise<ProviderInternship[]> {
    const route = this.providerRoute + "/internships";
    const { data: internships } = await this._instance.get(route);
    return internships;
  }

  async confirmInternship(internship: ConfirmInternshipBody): Promise<void> {
    await this._instance.put(`${this.providerRoute}/internships/${internship.id}:confirm`, internship);
  }

  async updateInternship(): Promise<void> {
    // async updateInternship(internship: UpdateInternshipBody): Promise<void> {
    // await this._instance.put(`${this.providerRoute}/trainings/${training.trainingId}`, training);
    throw new Error("Method not implemented.");
  }
}
