import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FundingRequestForm } from "./funding-request-form.component";
import { useFundingRequestReviewed } from "./use-funding-request-reviewed";
import { useParams } from "react-router-dom";
import {
  resetRetrieveFundingRequest,
  retrieveFundingRequest,
} from "../../../read/application/use-cases/admin/funding-request-retrieval/retrieve-funding-request";
import { ReviewFundingRequestBody } from "@academy-context/write/domain/types/admin/funding-request";
import { AppState } from "@redux/app-state";
import { deleteFundingRequest } from "../../../write/application/use-cases/admin/funding-request-deletion/delete-funding-request";
import { useFundingRequestDeleted } from "./use-funding-request-deleted";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import {
  resetReviewFundingRequest,
  reviewFundingRequest,
} from "@academy-context/write/application/use-cases/admin/funding-request-review/review-funding-request";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import {
  adminRetrieveProviderTrainings,
  resetAdminRetrieveProviderTrainings,
} from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { selectProviderRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { selectTeachersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/selectors/teachers-list-selectors";
import { adminSelectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/selectors/provider-training-list-selectors";

export const FundingRequestDetail = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.fundingRequestRetrieval);
  const { providers } = useAppSelector(selectProviderRetrievalForList);
  const { data: teachers } = useAppSelector(selectTeachersRetrievalForList);
  const [provider, setProvider] = useState<Nullable<string>>(null);
  const { trainings: providerTrainings } = useAppSelector(adminSelectProviderTrainingsRetrievalForList);

  useEffect(() => {
    if (id) dispatch(retrieveFundingRequest(id));
    dispatch(adminRetrieveTeachers());
    dispatch(adminRetrieveProviders());
    return () => {
      dispatch(resetReviewFundingRequest());
      dispatch(resetRetrieveFundingRequest());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (provider) dispatch(adminRetrieveProviderTrainings(provider));
    else dispatch(resetAdminRetrieveProviderTrainings());
  }, [dispatch, provider]);

  const saveFundingRequest = async (fundingRequest: ReviewFundingRequestBody) => {
    if (id) await dispatch(reviewFundingRequest({ id, body: fundingRequest }));
  };

  const onDeleteFundingRequest = async () => {
    if (id) await dispatch(deleteFundingRequest(id));
  };

  useFundingRequestReviewed();
  useFundingRequestDeleted();

  return (
    <>
      {data && (
        <FundingRequestForm
          onSubmit={saveFundingRequest}
          onDelete={onDeleteFundingRequest}
          fundingRequest={data}
          setProvider={setProvider}
          providers={providers}
          teachers={teachers}
          providerTrainings={providerTrainings}
        />
      )}
    </>
  );
};
