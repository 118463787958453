import { InvitationsListContainer } from "@user-management-context/primary/admin/user-invitations/list/invitation-list.container";
import { UserInvitationFormContainer } from "@user-management-context/primary/admin/user-invitations/form/user-invitations-form.container";
import { ProviderStudentInvitationListContainer } from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/provider-student-invitation-list.container";

export const InvitationsPage = () => {
  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-2">
          <UserInvitationFormContainer />
        </div>
        <div className="col-span-4">
          <ProviderStudentInvitationListContainer />
        </div>
      </div>
      <InvitationsListContainer />
    </>
  );
};
