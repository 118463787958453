import { SyntheticEvent, useEffect, useRef, useState } from "react";

import { retrieveToReviewFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/to-review-funding-request-list-retrieval/retrieve-to-review-funding-requests";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Link } from "react-router-dom";
import { useFundingRequestUpdated } from "./use-funding-request-updated";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import {
  FUNDING_REQUEST_ERROR_REASONS,
  ReviewedFundingRequestVM,
  SubmittedToFunderFundingRequestVM,
  ToReviewFundingRequestVM,
} from "@academy-context/read/domain/types/admin/funding-request";
import { formatDateToLocale } from "../../../../utils/formatting";
import { retrieveReviewedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/reviewed-funding-request-list-retrieval/retrieve-reviewed-funding-requests";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-afdas/retrieve-submitted-to-afdas-funding-requests";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shared-kernel/primary/shared/shadcn/ui/tabs";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { deleteFundingRequest } from "@academy-context/write/application/use-cases/admin/funding-request-deletion/delete-funding-request";
import { updateFundingRequestError } from "@academy-context/write/application/use-cases/admin/funding-request-error-update/update-funding-request-error";
import { cancelAndDuplicateFundingRequest } from "@academy-context/write/application/use-cases/admin/funding-request-cancel-and-duplicate/cancel-and-duplicate-funding-request";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { SendAfdasRefreshNotificationModal } from "@academy-context/primary/admin/funding-request-list/send-afdas-refresh-notification-modal";
import { AFDAS_FUNDER } from "@academy-context/primary/admin/funding-request-detail/form-validation/afdas-funding-request";
import { submitFundingRequestToAfdas } from "@academy-context/write/application/use-cases/admin/funding-request-submission-to-afdas/submit-funding-request-to-afdas";

const linkWrapper = (id: string, value: string) => (
  <Link to={`/fundingRequests/${id}`}>
    <div className="size-full">
      <span>{value}</span>
    </div>
  </Link>
);

const errorReasonLabelMap: Record<FUNDING_REQUEST_ERROR_REASONS, string> = {
  [FUNDING_REQUEST_ERROR_REASONS.WAITING_PERIOD]: "Carence",
  [FUNDING_REQUEST_ERROR_REASONS.PASSWORD]: "Mot de passe",
  [FUNDING_REQUEST_ERROR_REASONS.AFDAS_REFUSAL]: "Refus AFDAS",
  [FUNDING_REQUEST_ERROR_REASONS.OTHER]: "Autre",
  [FUNDING_REQUEST_ERROR_REASONS.NO_ERROR]: "Pas de problème",
};

const errorReasonOptions = Object.values(FUNDING_REQUEST_ERROR_REASONS).map(e => ({ label: errorReasonLabelMap[e], value: e }));

const pendingFundingRequestscolumns: ColumnDef<ToReviewFundingRequestVM>[] = [
  {
    accessorKey: "duplicateOriginId",
    header: () => {},
    cell: () => {},
    enableSorting: false,
    meta: {
      getCellContext: (context: CellContext<ToReviewFundingRequestVM, unknown>) => {
        const isDuplicate = context.getValue();
        return {
          className: isDuplicate ? "bg-theme" : "",
        };
      },
      title: "Retour validation",
      width: "30px",
    },
  },
  {
    accessorKey: "creationDate",
    header: () => "Date demande élève",
    cell: info => linkWrapper(info.row.original.id, formatDateToLocale(info.row.original.creationDate)),
    meta: {
      title: "Date demande élève",
      width: "150px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.name",
    header: () => "Elève",
    cell: info => linkWrapper(info.row.original.id, info.row.original.student.name),
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.phone",
    header: () => "Téléphone",
    cell: info => linkWrapper(info.row.original.id, info.row.original.student.phone),
    meta: {
      title: "Téléphone",
    },
  },
];

const reviewedFundingRequestscolumns: ColumnDef<ReviewedFundingRequestVM | SubmittedToFunderFundingRequestVM>[] = [
  {
    accessorKey: "reviewDate",
    header: () => "Date de finalisation (par Brigitte)",
    cell: info => formatDateToLocale(info.row.original.reviewDate),
    meta: {
      title: "Date de finalisation (par Brigitte)",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "training.startDate",
    header: () => "Date début stage",
    cell: info => formatDateToLocale(info.row.original.training.startDate),
    meta: {
      title: "Date début stage",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.name",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "submissionDate",
    header: () => "Date de mise en ligne AFDAS",
    cell: info => {
      const value = info.row.original.submissionDate;
      return value ? formatDateToLocale(value) : "";
    },
    meta: {
      title: "Date de mise en ligne AFDAS",
      width: "90px",
    },
    enableSorting: true,
  },
];

export const FundingRequestList = () => {
  const dispatch = useAppDispatch();
  const { data: toReview } = useAppSelector((state: AppState) => state.toReviewFundingRequestsRetrieval);
  const { data: reviewed } = useAppSelector((state: AppState) => state.reviewedFundingRequestsRetrieval);
  const { data: submitted } = useAppSelector((state: AppState) => state.submittedToFunderFundingRequestsRetrieval);
  const [dataColumns, setDataColumns] = useState<ColumnDef<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM>[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const currentFundingRequest = useRef<Nullable<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM>>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isAfdasRefreshNotificationModalOpen, setIsAfdasRefreshNotificationModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(retrieveToReviewFundingRequests());
    dispatch(retrieveReviewedFundingRequests());
    dispatch(retrieveSubmittedToFunderFundingRequests({ funders: [AFDAS_FUNDER] }));
  }, [dispatch]);

  useEffect(() => {
    const statusColumn: ColumnDef<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM> = {
      accessorKey: "status",
      header: () => "Mise en ligne AFDAS",
      meta: {
        title: "Mise en ligne AFDAS",
        width: "90px",
      },
      enableSorting: true,
      cell: info => {
        const { row } = info;
        const fundingRequest = row.original;
        const isSubmittedToAFDAS = info.row.original.status === "submitted_to_funder";
        return (
          <Checkbox
            id={fundingRequest.id}
            defaultChecked={isSubmittedToAFDAS}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              dispatch(submitFundingRequestToAfdas({ id: fundingRequest.id, hasBeenSubmitted: !isSubmittedToAFDAS }));
            }}
          />
        );
      },
    };
    const errorColumn: ColumnDef<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM> = {
      accessorKey: "error",
      header: () => "Problème",
      meta: {
        title: "Problème",
        width: "280px",
      },
      cell: ({ row }) => {
        const {
          original: { id, error },
        } = row;
        const isFundingRequestDeletable =
          error &&
          [
            FUNDING_REQUEST_ERROR_REASONS.AFDAS_REFUSAL,
            FUNDING_REQUEST_ERROR_REASONS.WAITING_PERIOD,
            FUNDING_REQUEST_ERROR_REASONS.OTHER,
          ].includes(error);
        return (
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center">
              <Combobox
                options={errorReasonOptions}
                value={error ?? ""}
                onChange={value => {
                  if (value === FUNDING_REQUEST_ERROR_REASONS.PASSWORD) {
                    setIsAfdasRefreshNotificationModalOpen(true);
                    currentFundingRequest.current = row.original;
                  } else
                    dispatch(
                      updateFundingRequestError({ id, error: value as FUNDING_REQUEST_ERROR_REASONS, sendNotificationToStudent: false })
                    );
                }}
                placeholder="Selectionner"
                search={{
                  notFoundText: "Pas d'erreur trouvée.",
                  commandInputPlaceHolder: "Chercher erreur...",
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              {isFundingRequestDeletable && (
                <>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      currentFundingRequest.current = row.original;
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    Supprimer
                  </Button>
                  <Button
                    onClick={() => {
                      currentFundingRequest.current = row.original;
                      setIsCancelModalOpen(true);
                    }}
                  >
                    Retour validation
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      },
    };
    const [reviewDate, startDate, student, submissionDate] = reviewedFundingRequestscolumns;
    if (reviewDate && startDate && student && submissionDate) {
      setDataColumns([
        reviewDate as ColumnDef<ReviewedFundingRequestVM | SubmittedToFunderFundingRequestVM>,
        startDate as ColumnDef<ReviewedFundingRequestVM | SubmittedToFunderFundingRequestVM>,
        student as ColumnDef<ReviewedFundingRequestVM | SubmittedToFunderFundingRequestVM>,
        statusColumn,
        submissionDate as ColumnDef<ReviewedFundingRequestVM | SubmittedToFunderFundingRequestVM>,
        errorColumn,
      ]);
    }
  }, [dispatch, reviewed, submitted]);

  useFundingRequestUpdated();

  return (
    <Tabs defaultValue="toReview" className="mt-5">
      <AlertModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={() => {
          setIsDeleteModalOpen(false);
          if (currentFundingRequest.current) dispatch(deleteFundingRequest(currentFundingRequest.current.id));
        }}
        title="Supprimer la demande de financement AFDAS"
        body="Êtes-vous sûr de vouloir supprimer cette demande?"
        submitText="Supprimer"
      />
      <AlertModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onSubmit={() => {
          setIsCancelModalOpen(false);
          if (currentFundingRequest.current) dispatch(cancelAndDuplicateFundingRequest(currentFundingRequest.current.id));
        }}
        title="Retourner à l'étape de validation de la demande de financement AFDAS"
        body="Êtes-vous sûr de vouloir retourner à l'étape de validation de cette demande?"
        submitText="Valider"
      />
      <SendAfdasRefreshNotificationModal
        fundingRequestId={currentFundingRequest.current?.id ?? null}
        student={currentFundingRequest.current?.student.name ?? null}
        isOpen={isAfdasRefreshNotificationModalOpen}
        onClose={() => setIsAfdasRefreshNotificationModalOpen(false)}
      />
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="toReview">Demandes envoyées par l’élève</TabsTrigger>
        <TabsTrigger value="reviewed">Demandes envoyées à Livane</TabsTrigger>
      </TabsList>
      <TabsContent value="toReview">
        <Card>
          <CardContent className="space-y-2">
            <DataTable
              columns={pendingFundingRequestscolumns}
              data={toReview}
              sortField="creationDate"
              order="desc"
              searchPlaceHolder="Rechercher une demande de financement"
            />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="reviewed">
        <Card>
          <CardContent className="space-y-2">
            <DataTable
              columns={dataColumns}
              data={[...reviewed, ...submitted]}
              sortField="training_startDate"
              order="asc"
              searchPlaceHolder="Rechercher une demande de financement"
            />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
};
