import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TokenStatus } from "../../../shared/domain/types/enums/token";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import {
  ProviderAccountCreationFormInputs,
  schema,
  defaultValues,
  providerAccountCreationFormatDataToFormData,
  providerAccountCreationFormatFormDataToBodyData,
} from "./form-validation/provider";
import { generateMessage } from "../../shared/account-creation";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { PasswordInput } from "../../shared/password-input/password-input.components";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ScrollArea } from "@shared-kernel/primary/shared/shadcn/ui/scroll-area";
import { CreateProviderAccountBody } from "@user-management-context/write/domain/types/provider/provider";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  provider: Provider;
  onSubmit: (body: CreateProviderAccountBody) => Promise<void>;
  token: {
    status: TokenStatus;
    id: Nullable<string>;
  };
  userId: Nullable<string>;
}

export const ProviderAccountCreationComponent = ({ provider, onSubmit, userId, token }: Props) => {
  const methods = useForm<ProviderAccountCreationFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const form = methods;
  const {
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = form;

  useEffect(() => {
    if (provider) reset(providerAccountCreationFormatDataToFormData(provider));
  }, [reset, provider]);

  const handleOnSubmit = async (formBody: ProviderAccountCreationFormInputs) => {
    if (token.id && userId) {
      const body = providerAccountCreationFormatFormDataToBodyData(formBody, userId, token.id, provider.providerId);
      onSubmit(body);
    } else throw Error("Missing data");
  };

  const phone = watch("mobile");

  if (token.status !== "valid") return generateMessage(token.status);
  if (!userId) return generateMessage("invalid");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)}>
        <ScrollArea className="h-[500px]" displayScrollBar={true}>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Raison sociale</FormLabel>
                  <FormControl>
                    <Input {...field} className="text-black" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <Label>Email</Label>
              <Input value={provider.email} disabled className="text-black" />
            </div>
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN (zone Euro)</FormLabel>
                  <FormControl>
                    <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" className="text-black" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="siret"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SIRET</FormLabel>
                  <FormControl>
                    <Input {...field} className="text-black" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Adresse complète du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} className="text-black" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="additionalAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ville et code postal du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} className="text-black" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <PasswordInput control={form.control} password={watch("password")} />
            <div className="col-span-2 mb-10 flex justify-end">
              <Button type="submit" disabled={!isDirty} className="ml-2">
                Créer compte
              </Button>
            </div>
          </div>
        </ScrollArea>
      </form>
    </Form>
  );
};
