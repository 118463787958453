import { useEffect } from "react";
import { retrieveTransferSummary } from "../../../read/application/use-cases/admin/admin-transfer-summary-retrieval/retrieve-transfer-summary";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectTransfersRetrievalForSummaryList } from "../../../read/application/use-cases/admin/admin-transfer-summary-retrieval/selectors/transfers-summary-selectors";
import { TransfersTable } from "../shared/components/transfer/transfers-table";
import { useTransferUpdate } from "../shared/components/transfer/use-transfers-update";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shared-kernel/primary/shared/shadcn/ui/tabs";
import { RefundsTable } from "@academy-context/primary/admin/shared/components/transfer/refunds-table";
import { AppState } from "src/redux/app-state";
import { retrieveRefundList } from "@academy-context/read/application/use-cases/admin/refund-list-retrieval/retrieve-refund-list";
import { useRefundUpdate } from "@academy-context/primary/admin/shared/components/transfer/use-refunds-update";
import { resetUpdateTransfer } from "@academy-context/write/application/use-cases/admin/admin-transfer-update/update-transfer";
import { resetUpdateRefund } from "@academy-context/write/application/use-cases/admin/refund-update/update-refund";

export const TransfersSummary = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectTransfersRetrievalForSummaryList);
  const { data: refunds } = useAppSelector((state: AppState) => state.refundListRetrieval);
  const { current, previous } = data;

  useEffect(() => {
    dispatch(retrieveTransferSummary());
    dispatch(retrieveRefundList());
  }, [dispatch]);

  const onRefreshRefunds = () => {
    dispatch(resetUpdateRefund());
    dispatch(retrieveRefundList());
  };

  const onRefreshTransfers = () => {
    dispatch(resetUpdateTransfer());
    dispatch(retrieveTransferSummary());
  };

  useTransferUpdate({ onRefresh: onRefreshTransfers });
  useRefundUpdate({ onRefresh: onRefreshRefunds });

  return (
    <div className="space-y-5">
      <Tabs defaultValue="teachers" className="mt-5">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="teachers">Professeurs</TabsTrigger>
          <TabsTrigger value="students">Elèves</TabsTrigger>
        </TabsList>
        <TabsContent value="teachers">
          <div className="space-y-5">
            <TransfersTable transfers={current.transfers} title={current.label} cardClassname="mb-3" displayFromTo={false} />
            <TransfersTable transfers={previous.transfers} title={previous.label} />
          </div>
        </TabsContent>
        <TabsContent value="students">
          <RefundsTable refunds={refunds} />
        </TabsContent>
      </Tabs>
    </div>
  );
};
