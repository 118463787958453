import { NumberGenerator } from "@shared-kernel/application/ports/number-generator";

export interface Adapters {
  numberGenerator: NumberGenerator;
}

export let commonAdapters: Partial<Adapters> = {};

export const initCommonAdapters = (adapters: Partial<Adapters>) => {
  commonAdapters = adapters;
};
