import { Nullable } from "@shared-kernel/core/types/nullable";
import { DownloadLink } from "@shared-kernel/primary/shared/download-link/download-link.components";
import { TrainingElement } from "./element";
import { AdminTrainingElementStatus } from "@academy-context/read/domain/types/training";

interface Props {
  title: string;
  url: Nullable<string>;
  action?: boolean;
}

export const DownloadableElement = ({ title, url, action }: Props) => {
  let status: AdminTrainingElementStatus = "not_available";
  if (action) status = "available";
  else if (url) status = "done";
  const content = <TrainingElement title={title} status={status} />;
  if (!url) return content;
  return <DownloadLink to={url}>{content}</DownloadLink>;
};
