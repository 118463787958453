import { useAppDispatch } from "@redux/hooks";
import { TeacherForm } from "./teacher-form.component";
import { createTeacher } from "../../../../user-management-context/write/application/use-cases/admin/admin-teacher-creation/create-teacher";
import { CreateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";
import { useTeacherCreate } from "./use-teacher-create";

export const TeacherCreate = () => {
  const dispatch = useAppDispatch();

  const saveTeacher = async (teacher: CreateTeacherBody) => {
    await dispatch(createTeacher(teacher));
  };

  useTeacherCreate();

  return <TeacherForm onSubmit={saveTeacher} />;
};
