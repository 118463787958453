import { useEffect } from "react";
import { UpdateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useTeacherUserUpdate } from "./use-teacher-user-update";
import { TeacherUserForm } from "./teacher-user-form.component";
import { retrieveTeacherProfile } from "../../../read/application/use-cases/teacher/teacher-profile-retrieval/retrieve-teacher";
import { selectTeacherProfileRetrieval } from "../../../read/application/use-cases/teacher/teacher-profile-retrieval/selectors/teacher-profile-selectors";
import { updateTeacherUser } from "../../../write/application/use-cases/teacher/teacher-update/update-teacher";

export const TeacherUserDetail = () => {
  const dispatch = useAppDispatch();
  const teacher = useAppSelector(selectTeacherProfileRetrieval);

  useEffect(() => {
    dispatch(retrieveTeacherProfile());
  }, [dispatch]);

  const saveTeacher = async (body: UpdateTeacherBody) => {
    await dispatch(updateTeacherUser(body));
  };

  useTeacherUserUpdate();

  return <TeacherUserForm teacher={teacher} onSubmit={saveTeacher} />;
};
