import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { ProviderStudentInvitationRow } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/selectors/provider-student-invitation-list-selectors";
import {
  ProviderStudentInvitationFormInputs,
  formatProviderStudentInvitationDataToFormData,
  providerStudentInvitationSchema,
  providerStudentInvitationdefaultValues,
  formatFormDataToBodyData,
} from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/form-validation/provider-student-invitation";
import { AcceptProviderStudentInvitationBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { PhoneInput } from "@user-management-context/primary/shared/phone-input/phone-input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import {
  doesUserExist,
  resetDoesUserExist,
} from "@user-management-context/read/application/use-cases/admin/user-account-existence/does-user-account-exist";

interface Props {
  invitation: ProviderStudentInvitationRow;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { id: string; body: AcceptProviderStudentInvitationBody }) => void;
  onDelete: (id: string) => void;
}

export const AcceptProviderStudentInvitationModal = ({ invitation, isOpen, onClose, onSubmit, onDelete }: Props) => {
  const dispatch = useAppDispatch();
  const { doesExist: doesAccountExist } = useAppSelector((state: AppState) => state.userAccountExistence);
  const form = useForm<ProviderStudentInvitationFormInputs>({
    resolver: yupResolver(providerStudentInvitationSchema),
    defaultValues: providerStudentInvitationdefaultValues,
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { handleSubmit, setValue, reset, watch, setError, clearErrors } = form;

  const isAlreadyExisting = invitation.originalData.student.isAlreadyExisting;

  const handleOnClose = () => {
    dispatch(resetDoesUserExist());
    onClose();
  };

  const handleOnSubmit = async (formBody: ProviderStudentInvitationFormInputs) => {
    handleOnClose();
    const body = formatFormDataToBodyData(formBody);
    onSubmit({ id: invitation.id, body });
  };

  const handleOnDelete = () => {
    handleOnClose();
    onDelete(invitation.id);
  };

  useEffect(() => {
    reset(formatProviderStudentInvitationDataToFormData(invitation));
  }, [invitation, reset]);

  const email = watch("email");
  // This part is used to check if user account is already registered.
  // We only check for non existing students because this part is supposed to be migrated elsewhere
  useEffect(() => {
    if (email && !isAlreadyExisting) dispatch(doesUserExist(email));
  }, [dispatch, email, isAlreadyExisting]);

  useEffect(() => {
    if (!isAlreadyExisting) {
      if (doesAccountExist) {
        setError("email", { message: "Cet utilisateur dispose déjà d'un compte", type: "custom" });
        setDisableSubmit(true);
      } else {
        clearErrors("email");
        setDisableSubmit(false);
      }
    }
  }, [clearErrors, doesAccountExist, isAlreadyExisting, setError]);

  const phone = watch("mobile");
  const today = new Date().toISOString();

  return (
    <Dialog open={isOpen} onOpenChange={handleOnClose}>
      <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={handleOnClose}>
        <DialogHeader>
          <DialogTitle>Nouvel élève via prestataire</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid gap-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="email" className="text-right">
                  Email
                </Label>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="email" {...field} disabled={isAlreadyExisting} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Prénom
                </Label>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled={isAlreadyExisting} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="lastName" className="text-right">
                  Nom
                </Label>
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled={isAlreadyExisting} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="mobile" className="text-right">
                  Téléphone
                </Label>
                <FormField
                  control={form.control}
                  name="mobile"
                  render={() => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <PhoneInput
                          phone={phone}
                          onChange={value => {
                            setValue("mobile", value as string, { shouldDirty: true });
                          }}
                          disabled={isAlreadyExisting}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="training" className="text-right">
                  Formation demandée
                </Label>
                <FormField
                  control={form.control}
                  name="training"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="internshipInterval.start" className="text-right">
                  Date de début de la formation
                </Label>
                <FormField
                  control={form.control}
                  name="internshipInterval.start"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-3 flex flex-col">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="internshipInterval.end" className="text-right">
                  Date de fin de la formation
                </Label>
                <FormField
                  control={form.control}
                  name="internshipInterval.end"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-3 flex flex-col">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <DialogFooter className="mt-4">
              <Button onClick={handleOnClose} variant="outline" type="button">
                Annuler
              </Button>
              <Button onClick={handleOnDelete} variant="destructive" type="button">
                Refus inscription
              </Button>
              <Button type="submit" disabled={disableSubmit}>
                Accepter{!isAlreadyExisting && " + mail d'invitation"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
