import { useEffect } from "react";
import { retrieveTrainingSessions } from "../../../read/application/use-cases/shared/training-sessions-retrieval/retrieve-training-sessions";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { AttendanceSheetForm } from "./attendance-sheet-form";
import { teacherRetrieveTraining } from "../../../read/application/use-cases/teacher/training-retrieval/retrieve-training";

interface Props {
  isAdmin?: boolean;
}

export const TeacherAttendanceSheetForm = ({ isAdmin = false }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingSessions } = useAppSelector((state: AppState) => state.trainingSessionsRetrieval);
  const { data: training } = useAppSelector((state: AppState) => state.teacherTrainingRetrieval);

  useEffect(() => {
    if (id) dispatch(teacherRetrieveTraining(id));
    if (id) dispatch(retrieveTrainingSessions(id));
  }, [dispatch, id]);

  if (trainingSessions.length === 0 || !training || !id) return <></>;

  const formattedAttendanceSheets = trainingSessions.map(s => ({
    id: s.id,
    scheduledDate: new Date(s.scheduledDate),
    signatureUrl: s.teacherSignatureUrl,
    number: s.number,
  }));

  return (
    <AttendanceSheetForm
      role="teacher"
      title={`${training.title} | ${training.student} | ${training.afdasId}`}
      conventionDate={new Date(training.conventionDate)}
      attendanceSheets={formattedAttendanceSheets}
      trainingId={id}
      isAdmin={isAdmin}
    />
  );
};
