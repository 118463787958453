import { useEffect, useRef, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard2 } from "@shared-kernel/primary/shared/custom-card/custom-card-2";
import { CardContent } from "@components/ui/card";
import { DataTable } from "@components/ui/data-table";
import { ProviderStudentInvitationRow } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/selectors/provider-student-invitation-list-selectors";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { useModal } from "@hooks/useModal";
import { Eye } from "lucide-react";
import { AcceptProviderStudentInvitationBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { AcceptProviderStudentInvitationModal } from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/acceptation-provider-student-invitation-modal";

const columns: ColumnDef<ProviderStudentInvitationRow>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Date",
    cell: ({ row }) => row.original.formattedCreationDate,
    enableSorting: true,
    meta: {
      title: "Date",
      width: "90px",
    },
  },
  {
    accessorKey: "student.fullName",
    header: () => "Elève",
    enableSorting: true,
    meta: {
      title: "Elève",
      width: "100px",
    },
  },
  {
    accessorKey: "student.email",
    header: () => "Email",
    enableSorting: true,
    meta: {
      title: "Email",
      width: "90px",
    },
  },
  {
    accessorKey: "providerTag",
    header: () => "Prest.",
    enableSorting: true,
    meta: {
      title: "Prest.",
      width: "60px",
    },
  },
  {
    accessorKey: "training",
    header: () => "Formation",
    enableSorting: true,
    meta: {
      title: "Formation",
    },
  },
  {
    accessorKey: "internship.interval",
    header: () => "Dates",
    enableSorting: true,
    meta: {
      title: "Dates",
      width: "90px",
    },
  },
];

interface Props {
  pendingProviderStudentInvitations: ProviderStudentInvitationRow[];
  onSubmit: (data: { id: string; body: AcceptProviderStudentInvitationBody }) => void;
  onDelete: (id: string) => void;
}

export const ProviderStudentInvitationListComponent = ({ pendingProviderStudentInvitations, onSubmit, onDelete }: Props) => {
  const currentSelectedLineInfo = useRef<Nullable<ProviderStudentInvitationRow>>(null);
  const { isModalOpen, openModal, closeModal } = useModal();
  const [dataColumns, setDataColumns] = useState<ColumnDef<ProviderStudentInvitationRow>[]>([...columns]);

  useEffect(() => {
    const openDetailsColumn: ColumnDef<ProviderStudentInvitationRow> = {
      accessorKey: "id",
      header: () => <></>,
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Eye
            key={id}
            onClick={() => {
              openModal();
              currentSelectedLineInfo.current = row.original;
            }}
            className="cursor-pointer"
          />
        );
      },
      meta: {
        width: "30px",
      },
    };
    setDataColumns([...columns, openDetailsColumn]);
  }, [openModal, pendingProviderStudentInvitations]);

  return (
    <CustomCard2 title="Invitations élèves via prestataire" cardClassname="h-full">
      {currentSelectedLineInfo.current && (
        <AcceptProviderStudentInvitationModal
          invitation={currentSelectedLineInfo.current}
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      )}
      <CardContent className="space-y-2">
        <DataTable
          columns={dataColumns}
          data={pendingProviderStudentInvitations}
          sortField="creationDate"
          order="asc"
          searchPlaceHolder="Rechercher une invitation"
          pageSize={5}
        />
      </CardContent>
    </CustomCard2>
  );
};
