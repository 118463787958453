import { useState } from "react";
import { FormControl, FormField, FormItem, FormLabel } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { EyeIcon } from "lucide-react";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { PasswordCreationValidation } from "./password-creation-validation.components";
import { Control } from "react-hook-form";

interface Props {
  password: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
}

export const PasswordInput = ({ password, control }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormField
      control={control}
      name="password"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Mot de passe</FormLabel>
          <FormControl>
            <div className="relative">
              <EyeIcon
                className="absolute right-2 top-1/2 z-50 -translate-y-1/2 cursor-pointer"
                color="black"
                onClick={() => setShowPassword(!showPassword)}
              />
              <Input {...field} type={showPassword ? "text" : "password"} className="text-black" />
            </div>
          </FormControl>
          <PasswordCreationValidation password={password} />
        </FormItem>
      )}
    />
  );
};
