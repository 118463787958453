import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { useSelector } from "react-redux";
import { useTransferUpdate } from "../shared/components/transfer/use-transfers-update";
import { ConventionTransfersTable } from "../shared/components/transfer/convention-transfer/convention-transfers-table";
import { retrieveConventionTransferList } from "../../../read/application/use-cases/admin/admin-convention-transfer-list-retrieval/retrieve-convention-transfer-list";
import { selectConventionTransfersRetrievalForList } from "../../../read/application/use-cases/admin/admin-convention-transfer-list-retrieval/selectors/convention-transfers-list-selectors";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shared-kernel/primary/shared/shadcn/ui/tabs";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { resetUpdateTransfer } from "@academy-context/write/application/use-cases/admin/admin-transfer-update/update-transfer";

export const ConventionTransfersList = () => {
  const dispatch = useAppDispatch();
  const { all, toPay } = useSelector(selectConventionTransfersRetrievalForList);

  useEffect(() => {
    dispatch(retrieveConventionTransferList());
  }, [dispatch]);

  const onRefreshTransfers = () => {
    dispatch(resetUpdateTransfer());
    dispatch(retrieveConventionTransferList());
  };

  useTransferUpdate({ onRefresh: onRefreshTransfers });

  return (
    <Tabs defaultValue="toPay" className="mt-5">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="toPay">Conventions à payer</TabsTrigger>
        <TabsTrigger value="all">Toutes les conventions</TabsTrigger>
      </TabsList>
      <TabsContent value="toPay">
        <Card>
          <CardContent className="space-y-2">
            <ConventionTransfersTable transfers={toPay} />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="all">
        <Card>
          <CardContent className="space-y-2">
            <ConventionTransfersTable transfers={all} />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
};
