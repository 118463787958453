import { Card, CardContent, CardHeader, CardTitle } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Progress } from "@shared-kernel/primary/shared/shadcn/ui/progress";
import clsx from "clsx";
import { ChevronDown } from "lucide-react";
import { formatDateToLocale } from "@utils/formatting";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  convention: { student: string; hours: number; hoursDone: number; paymentThreshold: number; paymentDate: Nullable<string> };
}

const setPaymentLabel = (paymentDate: string | null, toPay: boolean): string => {
  if (paymentDate) return `Payée le ${paymentDate}`;
  if (toPay) return "À payer";
  return "Paiement";
};

export const OldConventionCard = ({ convention }: Props) => {
  const { student, hours, hoursDone, paymentThreshold, paymentDate } = convention;

  // This is done to handle when there is only 1 hour
  const threshold = Math.max(paymentThreshold, 1);
  const toPay = hoursDone >= threshold;
  const paymentLabel = setPaymentLabel(paymentDate ? formatDateToLocale(paymentDate) : null, toPay);
  const hasBeenPaid = Boolean(paymentDate);

  return (
    <Card className="mt-2 w-full bg-primary-foreground text-white dark:bg-primary-foreground dark:text-white">
      <CardHeader className="border-b border-gray-400 p-2">
        <CardTitle className="text-sm">Ancienne convention: {student}</CardTitle>
      </CardHeader>
      <CardContent className="flex h-[100px] items-center px-2 py-0">
        <div className="relative h-[8px] w-full">
          <div
            className={clsx("absolute flex -translate-x-1/2 -translate-y-full flex-col items-center", hasBeenPaid && "text-[#2ab514]")}
            style={{ left: `${(threshold / hours) * 100}%`, top: 0 }}
          >
            <span className="text-xs">{paymentLabel}</span>
            <ChevronDown height={15} />
          </div>
          <Progress value={(hoursDone / hours) * 100} indicatorClassName={clsx(hasBeenPaid && "bg-[#2ab514]")} />
        </div>
        <div className="ml-4 flex w-[60px] flex-col items-center justify-center pl-1">
          <span className="text-2xl">
            {hoursDone}/{hours}
          </span>
          <span className="text-center text-xs">heures prises</span>
        </div>
      </CardContent>
    </Card>
  );
};
