import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Separator } from "@components/ui/separator";
import { Card, CardHeader, CardTitle } from "@components/ui/card";

interface Props {
  title: string;
  headerChildren?: ReactNode;
  cardClassname?: string;
}

export const CustomCard2 = ({ title, children, headerChildren, cardClassname }: PropsWithChildren<Props>) => {
  return (
    <Card className={clsx("w-full", cardClassname)}>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>{title}</CardTitle>
        {headerChildren}
      </CardHeader>
      <Separator className="bg-gray-400" />
      {children}
    </Card>
  );
};
