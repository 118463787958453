import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale } from "../../../../utils/formatting";
import { studentRetrieveTrainings } from "../../../read/application/use-cases/student/trainings-retrieval/retrieve-trainings";
import { AppState } from "@redux/app-state";
import { Link } from "react-router-dom";
import { StudentTrainingListVM } from "@academy-context/read/domain/types/training";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";

const linkWrapper = (id: string, value: string) => (
  <Link to={`/trainings/${id}`}>
    <span>{value}</span>
  </Link>
);

const columns: ColumnDef<StudentTrainingListVM>[] = [
  {
    accessorKey: "isAvailableAction",
    header: () => "Action requise",
    cell: ({ row }) =>
      row.original.isAvailableAction ? (
        <div className="flex justify-center">
          <DangerSign />
        </div>
      ) : null,
    meta: {
      title: "Action requise",
      width: "60px",
    },
  },
  {
    accessorKey: "conventionDate",
    header: () => "Date",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.conventionDate)),
    meta: {
      title: "Date",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "title",
    header: () => "Votre formation",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.title),
    meta: {
      title: "Votre formation",
    },
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.teacher),
    meta: {
      title: "Professeur",
      width: "300px",
    },
    enableSorting: true,
  },
];

export const StudentTrainingList = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.studentTrainingsRetrieval);

  useEffect(() => {
    dispatch(studentRetrieveTrainings());
  }, [dispatch]);

  return (
    <CustomCard title="Suivi qualité de votre formation">
      <div className="mb-4 w-full">
        <p>
          Vous trouverez ci-dessous l’ensemble de vos formations AFDAS.
          <br />
          En cliquant sur le nom de la formation, vous pourrez compléter vos questionnaires, émarger vos feuilles de présences et remplir
          votre Projet Pédagogique.
        </p>
        <div className="flex">
          <DangerSign /> <span className="ml-2">: Lorsque ce logo apparait, une action de votre part est requise.</span>
        </div>
      </div>
      <Separator className="bg-gray-400" />
      <DataTable columns={columns} data={data} sortField="conventionDate" order="desc" searchPlaceHolder="Rechercher une formation" />
    </CustomCard>
  );
};
