import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Link, useNavigate } from "react-router-dom";
import { StudentListElement, USER_STATUS } from "../../../../user-management-context/read/domain/types/admin/student";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ColumnDef } from "@tanstack/react-table";
import { AppState } from "@redux/app-state";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { FileClock, MailCheck, MailX, UserCheck, UserX } from "lucide-react";

const linkWrapper = (id: string, value: Nullable<string>, isBlacklisted: boolean) => {
  const textStyle = isBlacklisted ? "font-extrabold text-theme" : "";
  return (
    <Link to={`/students/${id}`}>
      <div className="size-full">
        <span className={textStyle}>{value}</span>
      </div>
    </Link>
  );
};

const disabledWrapper = (value: Nullable<string>) => {
  return (
    <div className="size-full">
      <span>{value}</span>
    </div>
  );
};

const valueWrapper = (student: StudentListElement, value: Nullable<string>) => {
  if (student.details) {
    return linkWrapper(student.details.studentId, value, student.details.isBlacklisted);
  }
  return disabledWrapper(value);
};

const statusFormatter = (student: StudentListElement) => {
  let icon = <UserX color="red" />;
  if (student.details?.isFundingRequestInProgress) icon = <FileClock />;
  else if (student.userStatus === USER_STATUS.EXPIRED_INVITATION) icon = <MailX color="red" />;
  else if (student.userStatus === USER_STATUS.INVITATION_SENT) icon = <MailCheck />;
  else if (student.userStatus === USER_STATUS.REGISTERED) {
    icon = <UserCheck color="green" />;
  }
  return <div className="flex justify-center">{icon}</div>;
};

const columns: ColumnDef<StudentListElement>[] = [
  {
    accessorKey: "userStatus",
    header: () => "Statut",
    cell: info => statusFormatter(info.row.original),
    enableSorting: true,
    meta: {
      title: "Statut",
      width: "90px",
    },
  },
  {
    accessorKey: "name",
    header: () => "Prénom",
    cell: info => valueWrapper(info.row.original, info.row.original.name),
    sortUndefined: 1,
    enableSorting: true,
    meta: {
      title: "Prénom",
    },
  },
  {
    accessorKey: "lastName",
    header: () => "Nom de famille",
    cell: info => valueWrapper(info.row.original, info.row.original.lastName),
    sortUndefined: 1,
    enableSorting: true,
    meta: {
      title: "Nom de famille",
    },
  },
  {
    accessorKey: "details.balance",
    header: () => "Solde",
    cell: info => {
      const cellValue = info.row.original.details?.balance;
      const balance = cellValue != null ? formatToCurrency(cellValue) : null;
      return valueWrapper(info.row.original, balance);
    },
    enableSorting: true,
    sortUndefined: 1,
    meta: {
      title: "Solde",
      width: "90px",
    },
  },
  {
    accessorKey: "details.nextFundingDate",
    header: () => "Fin de carence",
    cell: info => {
      const cellValue = info.row.original.details?.nextFundingDate;
      const value = cellValue ? formatDateToLocale(cellValue) : null;
      return valueWrapper(info.row.original, value);
    },
    sortUndefined: 1,
    enableSorting: true,
    meta: {
      title: "Fin de carence",
      width: "90px",
    },
  },
  {
    accessorKey: "email",
    header: () => "Email",
    cell: info => valueWrapper(info.row.original, info.row.original.email),
    enableSorting: true,
    meta: {
      title: "Email",
    },
  },
];

export const StudentsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: students } = useAppSelector((state: AppState) => state.studentsRetrieval);

  useEffect(() => {
    dispatch(retrieveStudents());
  }, [dispatch]);

  const onNewStudent = () => {
    navigate("/students/create");
  };

  return (
    <CustomCard
      title="Elèves"
      headerChildren={
        <div>
          <Button onClick={onNewStudent}>Nouvel élève</Button>
        </div>
      }
    >
      <DataTable
        columns={columns}
        data={students}
        sortField="details_balance"
        order="asc"
        searchPlaceHolder="Rechercher un élève"
        pageSize={50}
      />
    </CustomCard>
  );
};
